import React from "react";

interface Params {
  text: string;
  isBig?: boolean;
  className?: string;
  handleClick: () => void;
}

const PrincipalButton = ({
  text,
  handleClick,
  isBig = false,
  className = ""
}: Params): any => {
  return (
    <button
      className={`btn-primary ${isBig && "btn-secondary"} ${className}`}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default PrincipalButton;
