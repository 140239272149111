import { Element } from "react-scroll";
import { useEffect } from "react";
interface Params {
  name: string;
  id?: string;
  className?: string;
  addBackground?: boolean;
  addPadding?: boolean;
  children?: any;
}

const Section = ({
  children,
  className = "",
  addBackground = false,
  addPadding = true,
  name,
  id
}: Params): any => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Element
      name={name}
      id={id}
      className={`${
        addBackground &&
        "2xl:bg-white 2xl:py-20 2xl:rounded-tl-200px 2xl:rounded-br-200px"
      }`}
    >
      <section
        className={`w-full flex flex-col ${addPadding && "1024px:px-28"} ${
          addBackground &&
          "bg-white  py-10 768px:px-28 1024px:py-20 rounded-tl-70px rounded-br-70px 1024px:rounded-tl-200px 1024px:rounded-br-200px"
        } max-w-[1536px] mx-auto ${className}`}
      >
        {children && children}
      </section>
    </Element>
  );
};

export default Section;
