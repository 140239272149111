import { InlineWidget } from "react-calendly";

const Calendar = (): any => {
  return (
    <div className="siks w-[30rem] 800px:w-[20rem] 900px:w-[30rem] max-w-[100%]">
      <InlineWidget
        url="https://calendly.com/cepyme/nuevos-proyectos"
        styles={{
          height: "40rem",
          width: "100%"
        }}
        pageSettings={{
          hideEventTypeDetails: true
        }}
      />
    </div>
  );
};

export default Calendar;
