import React from "react";
import arrow from "../../../assets/arrow.svg";
import { textButtons } from "../../../utils/Texts";
import { ServiceModel } from "../../general/models/ServiceModel";

interface Params {
  service: ServiceModel;
  button: string;
  handleClick: () => void;
  positionImage?: number;
}

const Service = ({ service, handleClick, positionImage }: Params): any => {
  return (
    <div
      onClick={handleClick}
      className="relative 600px:px-10 800px:px-0 mx-4 800px:mx-0 cursor-pointer mb-5"
    >
      <div
        className={`bg-white flex flex-col mx-auto 800px:mx-5 1024px:mx-auto 1280px:mx-9 mt-16 rounded-2xl w-14rem h-[20rem] lg:h-[18rem] shadow-lg relative 800px:content-between 800px:w-46 1024px:w-16rem ${
          positionImage === 2
            ? "mt-20 800px:mt-24"
            : positionImage === 3
            ? "mt-20 800px:mt-44"
            : "mt-20 800px:mt-44"
        }`}
      >
        <img
          src={service.image}
          alt=""
          className="h-7rem w-full absolute -top-16 object-cover rounded-tl-2xl rounded-tr-2xl"
        />
        <p className="text-black font-semibold text-base pt-16 800px:text-sm px-5 pb-2 800px:pb-5">
          {service.title}
        </p>
        <p className="px-5 text-color-light text-sm text-start font-normal leading-5 pb-1 800px:pb-6">
          {service.text}
        </p>
        <div
          className="flex content-between mt-auto mb-4 flex-row cursor-pointer"
          onClick={handleClick}
        >
          <p className="pl-5 text-color-primary text-sm pr-3">
            {textButtons.buttonBanner.es}
          </p>
          <img src={arrow} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service;
