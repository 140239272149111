import firebase from "./Firebase";

const functions = firebase.functions();
// functions.useEmulator("localhost", 5001);

export const callCloudFunction = async (
  name: string,
  data: JSON
): Promise<any> => {
  const callable = functions.httpsCallable(name);
  try {
    const res = await callable(data);
    return { estado: "OK", res };
  } catch (error) {
    console.log(error);
    return { estado: "ERROR", error };
  }
};
