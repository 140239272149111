import Markdown from "markdown-to-jsx";
import React from "react";
import "./style.css";

interface ReactQuillComponentProps {
  description: string;
  style: "full" | "small";
}

const ReactQuillComponent = ({
  description,
  style
}: ReactQuillComponentProps): JSX.Element | null => {
  function truncarText(text: string, limit: number): string {
    if (text?.length > limit && style === "small") {
      return text?.substring(0, limit) + "...";
    } else {
      return text;
    }
  }

  return (
    <div className="markdown">
      <Markdown className="">{truncarText(description, 40)}</Markdown>
    </div>
  );
};

export default ReactQuillComponent;
