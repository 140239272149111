import React, { useEffect, useState } from "react";
import { Link as LinkScroll } from "react-scroll";
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import menuIcon from "../../../assets/icons/menu.svg";
import closeIcon from "../../../assets/icons/close.svg";
import whatsapp from "../../../assets/general/whatsapp.svg";
import { ROUTES } from "../../../enums/routes.enum";

const Header: React.FC = (): any => {
  const location = useLocation().pathname;
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const isServices = location === ROUTES.SERVICES;
  const isBlog = location === ROUTES.BLOG;

  useEffect(() => {
    setTimeout(() => {
      const section = document.getElementById(location);
      if (section) {
        const offset = isServices ? 100 : 50;
        const sectionPosition =
          section.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = sectionPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }, 1000);
  }, [location]);

  return (
    <header className="w-full flex justify-between shadow-md fixed top-0 left-0 bg-white py-5 px-5 800px:px-10 z-20">
      <Link
        to="/"
        className="800px:ml-10"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <img src={logo} alt="logo cepyme" className="w-[10rem]" />
      </Link>
      <button className="md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
        <img
          className="w-[1.5rem] h-[1.5rem]"
          src={menuOpen ? closeIcon : menuIcon}
          alt=""
        />
      </button>
      <div
        className={`${
          menuOpen ? "left-0" : "left-[110%]"
        } pb-40 fixed top-0 w-[100vw] h-[100vh] flex flex-col justify-around bg-white mt-[6rem]
        md:justify-end md:relative md:w-auto md:h-auto md:pb-0 md:mt-0 md:bg-transparent md:flex-row
        md:left-0 md:mr-8
        items-center transition-all duration-300 ease-in-out scale-105`}
      >
        <LinkScroll
          className={`mx-7 cursor-pointer hover:text-secondary ${
            isBlog && "text-black"
          }`}
          to="Services"
          spy={true}
          smooth={true}
          activeClass={`${!isBlog && "text-secondary"}`}
          offset={-100}
          duration={500}
          onClick={() => {
            history.push(ROUTES.SERVICES);
            setMenuOpen(!menuOpen);
          }}
        >
          Servicios
        </LinkScroll>
        <LinkScroll
          className={`mx-7 cursor-pointer hover:text-secondary ${
            isBlog && "text-black"
          }`}
          to="Projects"
          spy={true}
          activeClass={`${!isBlog && "text-secondary"}`}
          smooth={true}
          offset={-50}
          duration={500}
          onClick={() => {
            history.push(ROUTES.SUCCESS_STORIES);
            setMenuOpen(!menuOpen);
          }}
        >
          Casos de éxito
        </LinkScroll>

        <LinkScroll
          className={`mx-7 cursor-pointer hover:text-secondary ${
            isBlog && "text-black"
          }`}
          to="AboutUs"
          spy={true}
          smooth={true}
          activeClass={`${!isBlog && "text-secondary"}`}
          offset={-50}
          duration={500}
          onClick={() => {
            history.push(ROUTES.OURS);
            setMenuOpen(!menuOpen);
          }}
        >
          Nosotros
        </LinkScroll>
        {!isBlog ? (
          <Link
            className={`mx-7 cursor-pointer hover:text-secondary ${
              isBlog && "text-black"
            }`}
            to={ROUTES.BLOG}
          >
            Blog
          </Link>
        ) : (
          <LinkScroll
            className={`mx-7 cursor-pointer hover:text-secondary ${
              isBlog && "text-secondary"
            }`}
            to="Blog"
            spy={true}
            activeClass={`${!isBlog && "text-secondary"}`}
            smooth={true}
            offset={-50}
            duration={500}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Blog
          </LinkScroll>
        )}
        <a
          href="https://api.whatsapp.com/send?phone=+573112762770"
          target="_blank"
          rel="noreferrer"
          className="bg-black px-3 py-3 w-14 bg-primary rounded-full 768px:hidden"
        >
          <img src={whatsapp} alt="" />
        </a>
        <LinkScroll
          to="Contact"
          onClick={() => {
            history.push(ROUTES.CONTACT);
            setMenuOpen(!menuOpen);
          }}
          spy={true}
          activeClass={`${!isBlog && "bg-secondary"}`}
          smooth={true}
          offset={-50}
          duration={500}
          className={`flex cursor-pointer mx-7 justify-center items-center bg-primary text-sl text-white w-[6rem] rounded-md h-[2.2rem] hover:bg-secondary transition-all duration-300 ease-in-out`}
        >
          Contacto
        </LinkScroll>
      </div>
    </header>
  );
};

export default Header;
