import { DataUser } from "../interface/user.interface";

   
   
   export const  formatDataUser = (dataArray: DataUser[]): any =>  {
    console.log({dataArray});
    
    return dataArray.map((data) => {
        console.log({data});
      return {
        image: data?.fields?.Imagen[0]?.thumbnails?.full?.url,
        name: data?.fields?.Name,
        text: data?.fields?.Cargo,
        hierarchyLevel: data?.fields?.Rol,
        description: data?.fields?.Descripción,
        skills: data?.fields?.Habilidades?.join(";"),
      };
    });
  }