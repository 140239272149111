import firebase from 'firebase'

// Producción
// const config = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID
// };

// Pruebas
const config = {
  apiKey: process.env.REACT_APP_TESTS_APIKEY,
  authDomain: process.env.REACT_APP_TESTS_AUTHDOMAIN,
  projectId: process.env.REACT_APP_TESTS_PROJECTID,
  storageBucket: process.env.REACT_APP_TESTS_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_TESTS_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_TESTS_APPID,
  measurementId: process.env.REACT_APP_TESTS_MEASUREMENTID
}

firebase.initializeApp(config)
firebase.analytics()

export default firebase
