import { services, textOurServices } from "../../../utils/Texts";
import iconQuestion from "../../../assets/question.svg";
import SectionText from "../../general/texts/SectionText";
import Section from "../../general/sections/Section";
import { Link } from "react-router-dom";
import Services from "./services/Services";
import { ROUTES } from "../../../enums/routes.enum";

const OurServices = (): any => {
  return (
    <Section name="Services" id={ROUTES.SERVICES}>
      <div className="w-full  flex items-end 1024px:justify-between flex-col-reverse 1024px:flex-row mb-8">
        <div className="flex mt-8 justify-center items-center w-full px-8 1024px:px-0 1024px:flex-col 1024px:items-start 1024px:w-[30%]">
          <p className="text-center 1024px:text-left text-dark-blue font-bold text-xl mt-8">
            {textOurServices.questions.title.es}
          </p>
          <Link to="/preguntas" className="button-questions">
            <div className="text-container hidden 800px:flex 800px:flex-col">
              <div className="w-48 text-lg text-white mr-4">
                {textOurServices.questions.button.es}
              </div>
            </div>
            <img src={iconQuestion} alt="" className="w-6 h-6" />
          </Link>
        </div>
        <SectionText
          text={textOurServices.description.es}
          title={textOurServices.title.es}
          left={false}
          className="1024px:w-[40rem] 800px:max-w-[65%] px-6 1023px:px-0 items-end"
        />
      </div>
      <Services list={services} />
    </Section>
  );
};

export default OurServices;
