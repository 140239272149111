import React, { useCallback, useEffect, useRef, useState } from "react";
import Section from "../../general/sections/Section";
import Breadcrumbs from "./Breadcrumbs";
import TagsComponent from "./Tags";
import CardBlog from "./CardBlog";
import Cards from "./Cards";
import SinResultados from "../../../assets/sin_resultados.svg";

import {
  AirtableRecord,
  RecordFields
} from "../../../interface/blog.interface";
import moment from "moment";
import "moment/locale/es";

import { getAllBlog } from "../../../service/action/getAirTable";
import { CircleLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../enums/routes.enum";

export const formatAndCapitalizeDate = (isoDate: any) => {
  const date = new Date(isoDate);
  moment.locale("es");

  return moment(date)
    .format("dddd, D MMMM YYYY, h:mm:ss A")
    .replace(/^\w/, (c) => c.toUpperCase());
};

const Blog = (): JSX.Element | null => {
  const [activeTag, setActiveTag] = useState<string>("Todos");
  const [blogData, setBlogData] = useState<AirtableRecord<RecordFields>[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<string>("");
  const observer = useRef<IntersectionObserver | null>(null);

  const getBlogs = useCallback(async (activeTag: string, offset: string) => {
    try {
      setLoading(true);
      const res = await getAllBlog(activeTag, offset);
      if (res && res?.data && res?.data?.records) {
        setBlogData((prevData: AirtableRecord<RecordFields>[]) => [
          ...prevData,
          ...res.data?.records
        ]);
        setOffset(res.data.offset);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los blogs:", error);
    }
  }, []);

  useEffect(() => {
    setBlogData([]);
    getBlogs(activeTag, "");
  }, [activeTag, getBlogs]);

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5
    };

    observer.current = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting && !loading && offset) {
          getBlogs(activeTag, offset);
        }
      },
      options
    );

    if (observer.current) {
      observer.current.observe(
        document.querySelector(".observe-bottom") as Element
      );
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, getBlogs, offset, activeTag]);

  const firstBlog = blogData.length > 0 ? blogData[0] : null;

  return (
    <>
      <Section addBackground={false} name={"Blog"}>
        <div className="w-full flex flex-col justify-center">
          <section className="relative flex px-[20px] mb-[340px] sm:mb-[440px] md:mb-[200px] justify-center w-screen  left-1/2 transform  bg-white -translate-x-1/2">
            <section className="relative max-w-[1536px]  1024px:px-28  flex flex-col items-left w-full h-[600px] bg-white 1024px:py-10 1024px:rounded-br-100px ">
              <div className="hidden md:block ">
                <Breadcrumbs />
              </div>
              <h1 className="text-sky-900  text-[25px] md:text-[58px] font-bold font-['Myriad Pro'] leading-[60.20px]">
                Conoce todas las herramientas para tu empresa en nuestro Blog
              </h1>
              <div className="flex">
                <div className="overflow-x-scroll scroll-transparent ">
                  <TagsComponent
                    activeTag={activeTag}
                    setActiveTag={setActiveTag}
                    isMultiple={true}
                  />
                </div>
              </div>
              <div className="App flex flex-col justify-center w-full translate-y-[4%] ">
                <h2 className="self-stretch  text-neutral-900 text-[32px] font-[Myriad_Pro] leading-[41.60px]">
                  Lo último
                </h2>
                {firstBlog && (
                  <Link
                    key={firstBlog?.id}
                    to={`${ROUTES.BLOG}/${firstBlog?.id}`}
                    className="block text-neutral-900 cursor-pointer"
                  >
                    <CardBlog
                      id={firstBlog.id}
                      category={firstBlog?.fields?.Categorias}
                      date={formatAndCapitalizeDate(firstBlog?.createdTime)}
                      title={firstBlog?.fields?.Titulo}
                      description={firstBlog?.fields?.Descripcion}
                      imageUrl={firstBlog?.fields?.Activos}
                    />
                  </Link>
                )}
                {!loading && !firstBlog && (
                  <div className="flex flex-col items-center w-full border-2 border-page mt-8 p-8 bg-white shadow-[0_6px_24px_0px_rgba(142,142,142,0.20)] rounded-2xl	">
                    <img
                      src={SinResultados}
                      alt="Sin resultados"
                      className=""
                    />
                    <p className="text-2xl font-bold mt-8">
                      ¡Upps! Parece que no hay contenido acerca de este tema
                    </p>
                    <p className="text-l mt-2">
                      Te invitamos a leer o explorar otros contenidos que
                      tenemos para ti que te puedan interesar
                    </p>
                  </div>
                )}
              </div>
            </section>
          </section>
          <div className="w-full px-5 lg:p-0 ">
            {blogData.length > 1 && (
              <h1 className="self-stretch text-neutral-900 text-[32px] font-[Myriad_Pro] leading-[41.60px]">
                Nuestros blogs
              </h1>
            )}

            <div className=" mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols- mb-4 gap-4">
              {blogData
                .filter((blog) => blog?.id !== firstBlog?.id)
                .map((blog) => (
                  <Link
                    key={blog?.id}
                    to={`${ROUTES.BLOG}/${blog?.id}`}
                    className="block text-neutral-900 cursor-pointer"
                  >
                    <Cards
                      id={blog?.id}
                      imageUrl={blog?.fields?.Activos}
                      date={formatAndCapitalizeDate(blog?.createdTime)}
                      title={blog?.fields?.Titulo}
                      description={blog?.fields?.Descripcion}
                      tag={blog?.fields?.Categorias}
                    />
                  </Link>
                ))}
              <div className="observe-bottom"></div>
            </div>
          </div>
        </div>

        {loading && (
          <p className="flex justify-center w-full mb-2  ">
            <CircleLoader color="#FFC000" />
          </p>
        )}
      </Section>
    </>
  );
};

export default Blog;
