import LeadingText from "./LeadingText";

interface Params {
  left?: boolean;
  text: string;
  className?: string;
}

const TextSection = ({ text, left = true, className = "" }: Params): any => {
  return (
    <p
      className={`pt-5 text-lg text-center text-color-default ${
        left ? "800px:text-left" : "800px:text-right"
      } ${className}`}
    >
      <LeadingText text={text} />
    </p>
  );
};

export default TextSection;
