interface Params {
  text: string;
  className?: string;
}

const LeadingText = ({ text, className = "" }: Params): any => {
  const textSplit = text.split("\n");
  return (
    <>
      {textSplit.map((seccion, i) => (
        <span key={seccion} className={className}>
          {seccion}
          {i < textSplit.length - 1 && <br />}
        </span>
      ))}
    </>
  );
};

export default LeadingText;
