import TextSection from "./TextSection";
import TitleSection from "./TitleSection";

interface Params {
  title: string;
  text: string;
  left?: boolean;
  className?: string;
  lineTop?: boolean;
}

const SectionText = ({
  title,
  text,
  left = true,
  className = "",
  lineTop = true
}: Params): any => {
  return (
    <div className={className}>
      <TitleSection left={left} text={title} lineTop={lineTop} />
      <TextSection left={left} text={text} />
    </div>
  );
};

export default SectionText;
