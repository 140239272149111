import { ServiceModel } from "../screens/general/models/ServiceModel";
import { TeamMemberModel } from "../screens/general/models/TeamMemberModel";

export const header = {
  options: {
    es: ["Servicios", "Nosotros", "Proyectos"]
  },
  button: {
    es: "Contacto"
  }
};

export const questions = [
  {
    question: {
      es: "¿Que significa internacionalizar mi pyme?"
    },
    answer: {
      es: "Es el proceso por el cual una empresa crea las condiciones precisas, realizando una planeación estratégica que involucre todas las áreas de la compañía para ingresar en otro país."
    }
  },
  {
    question: {
      es: "¿Qué beneficios tiene adelantar un proyecto de internacionalización en mi Pyme?"
    },
    answer: {
      es: "Hoy más que nunca es fundamental para las empresas ubicar oportunidades en diferentes mercados, no solo porque pueden aumentar sus ingresos hasta en un 40%, sino porque pueden ubicar nuevas relaciones comerciales, socios, proveedores, clientes y aliados estratégicos, además de diversificar sus riesgos al invertir en diferentes países."
    }
  },
  {
    question: {
      es: "¿Cómo puedo internacionalizar mi empresa?"
    },
    answer: {
      es: "Existen múltiples formas de realizar un proyecto de internacionalización, tales como: constitución de filiales o sucursales de sociedad extranjera, franquicias internacionales, representaciones comerciales, ventas internacionales, alianzas estratégicas, entre otras, aportando múltiples beneficios para la continuidad y proyección de la empresa."
    }
  },
  {
    question: {
      es: "¿Una empresa pequeña puede realizar un proceso de internacionalización?"
    },
    answer: {
      es: "Claro que si, cada empresa podrá encontrar un nicho de mercado específico a nivel mundial para su producto o servicio acorde a las capacidades reales de mi empresa y a mi presupuesto."
    }
  },
  {
    question: {
      es: "¿Es muy costoso entrar en mercados internacionales?"
    },
    answer: {
      es: "El acceso a internet puede permitir que una empresa tenga un proceso de internacionalización de muy bajo costo concentrando el presupuesto en la exportación del producto o servicio, hoy más que nunca es posible aprovechar estas herramientas para hacer un proceso de menor costo y mayor eficiencia."
    }
  },
  {
    question: {
      es: "¿Al ser una pyme puedo aprovechar beneficios que ofrecen los tratados de libre comercio?"
    },
    answer: {
      es: "Claro que si, una empresa no está sujeta a un tamaño o volumen de facturación para ser beneficiarias de los acuerdos comerciales que tiene el país."
    }
  },
  {
    question: {
      es: "¿Solo puedo internacionalizarme si viajo al país donde quiero vender mis productos?"
    },
    answer: {
      es: "El internet permite que una empresa pueda realizar gestión comercial de manera virtual, de igual manera la pandemia ha obligado a las empresas a optimizar el uso de herramientas virtuales, ferias y demás actividades comerciales sin requerir la presencia física del comprador y el vendedor, hoy es viable llegar a cualquier parte del mundo."
    }
  },
  {
    question: {
      es: "¿Me puedo internacionalizar solo o puedo hacer alianzas para asumir costos entre todos?"
    },
    answer: {
      es: "Las alianzas siempre serán una gran forma para disminuir costos, compartir experiencias, compartir el riesgo y optimizar los esfuerzos para la internacionalización."
    }
  },
  {
    question: {
      es: "¿Qué es una PYME?"
    },
    answer: {
      es: "La Ley 590 de 2000 modificada por la Ley 905 de 2044, reglamentó la clasificación del sector empresarial en micro, pequeñas y medianas empresas, las cuales cuentan con diferentes beneficios que les ayudan a su crecimiento, participación en el mercado y lograr el cumplimiento de sus objetivos. En ese sentido las PYMES son: Pequeñas empresas: Son aquellas que tienen entre 11 y 50 trabajadores, o activos totales por valor entre 501 SMMLV a 5.000 SMMLV; Medianas empresas: Son aquellas que tienen entre 51 y 200 trabajadores, o activos totales por valor entre 5.001 SMMLV a 30.000 SMMLV."
    }
  },
  {
    question: {
      es: "¿Es importante registrar mi marca en un proceso de expansión?"
    },
    answer: {
      es: "Dependiente de la estrategia de expansión que realice la marca tendrá un papel fundamental en la misma, si mi negocio lleva un producto o servicio con una trayectoria en el mercado y esta misma es la que impulsa el proyecto de internacionalización será fundamental proteger la marca en todos los mercados donde tenga operación actual o futura."
    }
  }
];

export const textButtons = {
  buttonTeam: {
    es: "Conóceme"
  },
  buttonBanner: {
    es: "Leer más"
  }
};

export const bannerTexts = {
  title: {
    es: "Soluciones integrales \npara la expansión de \ntu PYME"
  },
  description: {
    es: "Todo lo que su PYME necesita en un solo lugar. Es el momento de encontrar oportunidades en nuevos mercados"
  },
  textVideo: {
    es: "Mira las empresas con las que hemos trabajado"
  },
  urlVideo: {
    es: "https://youtu.be/oIt66AmNM0U"
  }
};

export const teamOne = [
  new TeamMemberModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/team%2FAljadys.jpg?alt=media&token=42ac53d5-59d8-4677-ab9d-5c8065707df8",
    "Aljadys Tamayo Gallego",
    "Directora comercial y nuevos negocios",
    "Dirección legal y corporativa",
    "Abogada y Negociadora Internacional, especialista en derecho comercial y de los negocios especialista en gerencia de negocios internacionales y MBA en alta dirección de empresas con especialidad en marketing internacional\n\nMás de 20 años de experiencia  en el sector público y privado, analista en Riesgos Corporativos – Oficial de Cumplimiento en Prevención de Lavado de Activos y Financiación del Terrorismo, formación en Gerencia de Proyectos, régimen Aduanero, Zonas Francas y Logística Internacional.\n\nProyectar y hacer crecer una empresa exige disciplina, trabajo en equipo,compromiso y mucha actitud positiva, pero es un gran esfuerzo que vale la pena cada día. ",
    "Capacidad de resolución de problemas con enfoque en la necesidad de cliente;Trabajo en equipo;Habilidades de comunicación servicio al cliente, liderazgo y disciplina;Negociación;Motivación;Resiliencia;Cultura empresarial"
  ),
  new TeamMemberModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/team%2FCarol.jpeg?alt=media&token=473bf5ff-ebdd-4b5f-9280-401aa7bee32d",
    "Carol Leyva",
    "Directora administrativa y financiera.",
    "Dirección legal y corporativa",
    "Contadora Pública, con más de 10 años de experiencia y Abogada con Especialización en Gerencia Tributaria\n\nAmplia experiencia en materia de impuestos y contabilidad en empresas industriales, comerciales, de servicios y entidades sin ánimo de lucro, Valoración de Empresas; Normas internacionales NIIF y conciliación extrajudicial; operaciones nacionales e internacionales, implementación en Sistemas LA/FT/FPADM; logística y comercio exterior.\n\n La vida es muy corta para quedarse con las ganas, materializa tus ideas con perseverancia y nunca te rindas. ",
    "Carisma;Disciplina;Comunicación;Servicio al cliente"
  )
];

export const teamTwo = [
  new TeamMemberModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/team%2FWilliam.jpg?alt=media&token=f68a168a-ee37-46fc-9b21-a47ab7b97633",
    "William Fernández",
    "Coordinador financiero",
    "Dirección legal y corporativa",
    "Contador con más de 27 años de experiencia,  experto en NIIF; calidad total Crosby, desarrollo e implementación de flujo gramas de trabajo y seguimiento, asesor financiero de empresas con operación nacional e internacional, asesoría en regímenes cambiarios y de inversión internacional, viabilidad financiera de proyectos, revisoría fiscal, auditoría y apoyo en la estructuración de empresas de alto impacto, amplio conocimiento en asesoría financiera, tributaria y contabilidad en el aumento de la rentabilidad, el flujo de caja y valor integral de la empresa\n\nEl éxito depende del esfuerzo. Sófocles",
    "Disciplina;Buen carácter;Liderazgo;Habilidades de comunicación y servicio al cliente"
  ),
  new TeamMemberModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/team%2FLuis_Chala.jpeg?alt=media&token=42fe0e1c-0a68-4bc0-b8ef-31894fe13c21",
    "Luis Alfonso Chala",
    "Coordinador comercio exterior y logística",
    "Dirección legal y corporativa",
    "Profesional en comercio internacional, MBA en Comercio Internacional y Ventas, especialista en inteligencia de mercados, diplomado en logística, entre otros.\n\nCuenta con 20 años de experiencia en comercio exterior y negocios internacionales; en su ejercer profesional ha tenido a cargo la coordinación y desarrollo de operaciones de importación-exportación; diseño, análisis y desarrollo de proyectos de investigación e inteligencia de mercados; investigación de tendencias culturales y de consumo; especialista en análisis de bases de datos; habilidades comerciales; determinación, seguimiento y control de costos en fábrica y para exportación; desarrollo y dirección de proyectos especiales.\n\nApunta a la luna. Si fallas, podrías dar a una estrella. W. Clement Stone",
    "Investigación y alta capacidad de análisis;Liderazgo;Alta comprensión;Soluciones integrales;Evaluación de proyectos"
  ),
  new TeamMemberModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/team%2FJUAN%20PABLO%20TAMAYO%20OROZCO.jpeg?alt=media&token=eea395bd-df03-45f7-aa0a-0dade623184b",
    "Juan Pablo Tamayo Orozco",
    "Coordinador derecho laboral, seguridad social y UGPP",
    "Dirección legal y corporativa",
    "Abogado, Especialista en seguridad social de la Universidad de Medellín cursando Maestría en Derecho del trabajo, procesal del trabajo y seguridad social, en la Universidad Externado de Colombia\n\nMás de cuatro años de experiencia profesional, con un profundo entendimiento en derecho laboral individual y colectivo, así como en seguridad social, abarcando áreas como salud, pensiones y riesgos laborales. Manejo efectivo de relaciones jurídico-laborales en pequeñas y medianas empresas, respaldada por un sólido historial en litigios en la jurisdicción laboral. Destaca por su compromiso y competencia en el ámbito legal.",
    "Disciplina;Organización;Negociación;Motivación;Trabajo en equipo;Resiliencia;Cultura empresarial"
  ),
  new TeamMemberModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/team%2FLILIANA%20VILLAMIZAR.png?alt=media&token=953e9bf3-0ae9-41e9-9df2-aea91ca1cab9",
    "Liliana villamizar ",
    "Coordinación compliance y gobierno corporativo",
    "Dirección legal y corporativa",
    "Abogada y especialista en sistemas de Gestión Empresarial con amplio conocimiento y más de 20 años de experiencia; ha desempeñado múltiples  cargos desde el área legal hasta área de calidad productividad, logrando implementación de sistemas de contratación interna, liderando diferentes proyectos Lean Six Sigma “Productividad del Recurso Humano”, “Proceso de Venta de Acabados, “Lean Proceso de Contratación.\n\nNo basta con querer: debes preguntarte a ti mismo qué vas a hacer para conseguir lo que quieres.  Franklin D. Roosevelt",
    "Gran capacidad para el logro de objetivos complejos;Estadística;Trabajo en equipo;Liderazgo;Disciplina"
  ),
  new TeamMemberModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/team%2FAlexandra_Salazar.jpeg?alt=media&token=5c5bad18-fbde-41f7-817a-9d6575c22a4c",
    "Alexandra Salazar",
    "Coordinadora reorganización empresarial",
    "Dirección legal y corporativa",
    "Abogada con experiencia profesional de mas de 15 años, especialmente en sectores como Oil & Gas, Transporte, Servicios, TI; amplios conocimientos en áreas del Derecho Comercial y Societario, Derecho Laboral y Seguridad Social, Derecho Civil, Derecho Administrativo e Insolvencia, operación bursátil y mercado de capitales. ASOBURSATIL. 2021;  Sistemas de gestión en Anti-soborno, Sistemas de Prevención de Lavado de Activos, Financiación del Terrorismo y Financiación de Proliferación de armas de destrucción masiva.\n\nLo que haces hoy puede mejorar todos tus mañanas. Ralph Marston",
    "Solución de conflictos;Alta capacidad de análisis;Gestión del tiempo;Evaluación de proyectos"
  )
];

export const services = [
  new ServiceModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/services%2FSERVICIOS.%20INTERNACIONALIZACION.png?alt=media&token=1d5a8fce-1f0f-4252-8bc2-af921b40bf26",
    "Internacionalización",
    "Evaluación legal, financiera, logística y de comercio exterior para fortalecer tu empresa y pprepararla para su peración exitosa.",
    "Toda empresa necesita prepararse para asumir nuevos retos a nivel nacional e internacional, para ello, es recomendable que profesionales especializados evalúen cada área de la empresa para identificar las oportunidades de mejora y el nivel de preparación para una internacionalización exitosa, esto es fundamental para plantear un proyecto de internacionalización que involucre todas las áreas responsables\n\nDiagnóstico básico legal, financiero, comercio exterior y logística internacional, $990.000 más IVA por cada área de diagnóstico. Diagnóstico Avanzado: Evaluamos tu empresa para proyectos especiales, según las necesidades específicas de la empresa. ",
    "",
    ""
  ),
  new ServiceModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/services%2FSERVICIO%20CONSULTORI%CC%81A%20LEGAL.jpg?alt=media&token=cd92a87d-4d0c-4024-9a46-3f2534b575db",
    "Consultoría Legal",
    "Brindamos todos los servicios requeridos por la empresa en Derecho Comercial Laboral, Tributario, Internacional, Aduanero, y Reorganización empresarial",
    "Parte importante de una internacionalización exitosa es contar con una adecuada estructura legal segura y eficiente con enfoque internacional, para ello brindamos los servicios legales adecuados para su pyme.\n\nLicencias y Permisos para operación de importación y exportación.\n\nDiseño e implementación del Programa de Protección de Datos Personales, Regímenes de inversión internacional Banco de la República, Régimen de Insolvencia, Reorganización empresarial y Régimen de Rescate Empresarial.",
    "En la consultoría legal te ofrecemos",
    "Outsourcing del área jurídica.;Perfil Legal país internacionalización;Consultoría integral en las áreas de  Derecho Laboral y de la Seguridad Social - UGPP, Derecho Tributario, Derecho Aduanero, Derecho Comercial, Derecho Público y Privado nacional e internacional, Propiedad Intelectual y Negocios Internacionales."
  ),
  new ServiceModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/services%2FSESRVICIOS%20CONSULTORI%CC%81A%20FINANCIERA.jpg?alt=media&token=34d810a0-2466-49a2-ac1d-b41ad0b729a0",
    "Consultoría Financiera y Cambiaria",
    "Asesoría en régimen cambiario e inversiones internacionales, Valoración de empresas, evaluación de proyectos, asesoría tributaria, Diseños de estrategias de tributación",
    "De nada sirve la búsqueda de nuevas oportunidades si no es viable obtener utilidades de ellas o estas se pierden por sanciones y riesgos no contemplados en una adecuada planeación financiera.\n\nLa viabilidad de las pymes cada vez más se ve comprometida de manera crítica al no contar con la adecuada planeación financiera, tributaria y cambiaria, por esto en Cepyme, brindamos una asesoría especializada y accesible para pymes con la finalidad de mejorar su planeación financiera, incrementar las utilidades y optimizar su operación nacional e internacional.",
    "",
    ""
  ),
  new ServiceModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/services%2FSERVICIOS.%20CONSULTORIA%20COMERCIO%20EXTERIOR%20.jpg?alt=media&token=75452562-8f43-41e4-9842-7cf4ad301104",
    "Consultoría en Comercio Exterior",
    "Formulación integral de proyectos de internacionalización, gestión comercial internacional, investigación y análisis de mercados.",
    "Hoy más que nunca es fundamental para las pymes ubicar oportunidades en diferentes mercados, no solo porque pueden aumentar sus ingresos hasta en 40%, sino porque  pueden ubicar nuevas relaciones comerciales, socios, proveedores, clientes y aliados estratégicos, además de diversificar los riesgos al invertir en diferentes países.\n\nContamos con un equipo de profesionales de gran trayectoria y experiencia en mercados internacionales para ofrecer a las pymes oportunidades adecuadas a su tamaño y operación, para ello contamos con los servicios requeridos para generar y acceder a oportunidades en mercados internacionales y transmitir dicha experiencia al equipo de cada empresa mediante la capacitación.\nFormulación proyectos de internacionalización, gestión comercial internacional, investigación y análisis de mercados, perfiles de productos/servicios, perfiles sectoriales, costeo de importación y exportaciones, ubicación de proveedores internacionales, planeación y desarrollo de misiones exploratorias comerciales virtuales.",
    "",
    ""
  ),
  new ServiceModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/services%2FCEPYME%20CONSULTORI%CC%81A%20LOGI%CC%81STICA.jpg?alt=media&token=0a593d74-0bd8-41db-96d7-895d618c439e",
    "Consultoría Logística",
    "Logística de importaciones y exportaciones, outsourcing de abastecimiento, comercialización internacional, disminución de costos logísticos, búsqueda y desarrollo de proveedores internacionales",
    "El éxito de todo el proyecto de internacionalización se encuentra en una planeación logística estratégica.\n\nLlegar o no llegar con los productos dependerá de una adecuada planeación de la cadena de suministro, es así, que contar con la experiencia para su optimización es vital para cualquier proyecto, para ello brindamos un portafolio de servicios eficiente, ágil, integral y accesible para que las pymes puedan cumplir con cada uno de sus proyectos, además de contar con aliados estratégicos para el adecuado transporte internacional de mercancías.",
    "",
    ""
  ),
  new ServiceModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/services%2FSERVICIOS.%20CAPACITACIO%CC%81N%20PERSONAL%20PYME.jpg?alt=media&token=e01703ef-3b54-4894-9284-953f1d0a5582",
    "Capacitación Personal Pymes",
    "Tu equipo de trabajo debe adquirir conocimiento especializado para ser parte del proceso de expansión de la pyme",
    "El recurso humano de una pyme debe saber como afrontar los nuevos retos que el mercado impone.\n\nEntendemos la importancia de saber hacer, por ello, brindamos el servicio de capacitación al personal de la empresa con la finalidad de transmitir el conocimiento para continuar con una internacionalización exitosa, ellos, deben apropiar todo el proyecto, conocer su dinámica, aportar y ejecutarlo de la mano de profesionales especializados que le asesoren durante su formacióin.",
    "",
    ""
  )
];

export const textAboutUs = {
  es: "CEPYME cuenta con un equipo de profesionales especilizado, de alto impacto, carismático, con altos estándares éticos y una vocación de servicio"
};

export const hierarchyLevels = {
  internalAddress: {
    es: "Dirección interna"
  },
  coordination: {
    es: "Coordinación"
  }
};

export const titleSection = {
  aboutUs: {
    es: "Nosotros"
  },
  frequentQuestions: {
    es: "Preguntas Frecuentes"
  },
  contact: {
    es: "Contacto"
  }
};
export const titleBlog = {
  blog: {
    es: "Soluciones integrales"
  },
  frequentQuestions: {
    es: "para la expansión de tu PYME"
  },
  contact: {
    es: "Contacto"
  }
};

export const textContact = {
  es: "Estamos para apoyarte en tus proyectos empresariales, por favor, haznos saber cómo podemos ayudarte"
};

export const textOurServices = {
  title: {
    es: "Nuestros Servicios"
  },
  description: {
    es: "Ofrecemos soluciones empresariales integrales y a la medida, con la finalidad de lograr una expansión nacional e internacional, capacitando durante este proceso al personal para que puedan realizar su proyecto de manera exitosa."
  },
  questions: {
    title: {
      es: "¿Tienes alguna pregunta?"
    },
    button: {
      es: "Preguntas Frecuentes"
    }
  }
};

export const textInput = {
  name: {
    es: "Nombre"
  },
  email: {
    es: "Email"
  },
  commentary: {
    es: "Comentario"
  }
};

export const placeholderInput = {
  name: {
    es: "Ingresa tu nombre"
  },
  email: {
    es: "Ingresa tu email"
  }
};

export const projectsDescription = {
  title: {
    es: "Casos de Éxito"
  },
  description: {
    es: "CEPYME es una empresa con más de 5 años en el mercado apoyando a las pymes en sus proyectos de expansión y operación internacional, brindando un acompañamiento adaptado a la necesidad de cada empresa"
  }
};

export const projects = {};
