import { useState } from "react";
import { textButtons } from "../../../../utils/Texts";
import DialogOurServices from "../dialogOurServices/DialogOurServices";
import Service from "../Service";
import { ServiceModel } from "../../../general/models/ServiceModel";
interface Params {
  list: Array<any>;
}

const Services = ({ list }: Params): any => {
  const [open, setOpen] = useState<ServiceModel>();

  const handleClickOpen = (service: ServiceModel) => {
    setOpen(service);
  };

  let n = 0;

  return (
    <div className="800px:flex 800px:justify-center">
      {open && (
        <DialogOurServices service={open} close={() => setOpen(undefined)} />
      )}
      <div className="flex justify-between overflow-x-auto mt-4 mb-10 800px:flex-wrap 800px:justify-around 800px:w-67em ourServices">
        {list.map((service, i) => {
          n += 1;
          if (n > 3) n = 1;

          return (
            <Service
              service={service}
              button={textButtons.buttonTeam.es}
              handleClick={() => handleClickOpen(service)}
              positionImage={n}
              key={`person-${i}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Services;
