import LeadingText from "../../../general/texts/LeadingText";
import arrow from "../../../../assets/arrow.svg";
import closeIcon from "../../../../assets/close-icon.svg";
import { ServiceModel } from "../../../general/models/ServiceModel";

interface ParamsService {
  service: ServiceModel;
  close: () => void;
}

const DialogOurServices = ({ service, close }: ParamsService): any => {
  return (
    <div className="fixed bg-black bg-opacity-25 w-screen h-screen -left-px z-50 top-0 flex flex-col items-center justify-center">
      <div
        className="absolute top-0 bg-blue flex flex-col items-center justify-end w-screen h-screen"
        onClick={close}
      />
      <div className="flex flex-col items-center bg-white relative w-72 h-30rem 400px:w-3/4 1024px:w-55rem 800px:h-38rem">
        <div className="overflow-y-auto scrollbar-styles 1024px:overflow-y-hidden flex flex-col">
          <div className="flex flex-col relative 1024px:h-full">
            <img
              className="object-cover h-56 800px:h-56 w-full"
              src={service.image}
            />
            <div className=" h-56 w-full absolute 800px:h-56"></div>
            <div className="flex justify-end items-center">
              <img
                src={closeIcon}
                alt=""
                className="absolute w-4 top-0 800px:w-8 mt-5 mr-5 cursor-pointer"
                onClick={close}
              />
            </div>
            <div className="h-26rem flex flex-col 1024px:overflow-y-auto scrollbar-styles">
              <div className="flex justify-start items-center mt-4 ml-6 800px:mt-8 800px:ml-10">
                <div className="bg-primary h-1 w-16 800px:w-48"></div>
              </div>
              <p className="text-start text-xs mt-1 px-6 400px:mt-3 800px:px-10 w-full 800px:mx-auto 800px:text-sm 800px:mt-5">
                <LeadingText text={service.description} />
              </p>
              {service.servicesOffered ? (
                <p className="text-start text-xs px-6 mt-1 400px:mt-3 800px:text-base font-bold 800px:px-10 w-3/4 800px:w-full 800px:mx-auto 800px:mt-5">
                  {service.titleServicesOffered}
                </p>
              ) : (
                ""
              )}
              {service.servicesOffered ? (
                <p className="text-start text-xs px-6 mt-1 400px:mt-3 800px:text-base 800px:px-10 800px:mt-2">
                  {service.servicesOffered
                    .split(";")
                    .map((servicesOffered, i) => {
                      return (
                        <div
                          key={`skill-${i}`}
                          className="flex justify-start mb-3"
                        >
                          <span className="mr-2">•</span>
                          <LeadingText text={servicesOffered} />
                        </div>
                      );
                    })}
                </p>
              ) : (
                ""
              )}
              <div
                onClick={close}
                className="flex mt-auto mb-5 800px:px-10 text-sm px-5 800px:text-base underline text-color-primary cursor-pointer"
              >
                Volver
                <img src={arrow} alt="" className="px-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogOurServices;
