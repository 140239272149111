import ImageCurve from "../../../general/images/ImageCurve";
import statistics from "../../../../assets/icons/statistics.svg";
import { ProjectModel } from "../../../general/models/ProjectModel";
import TitleSection from "../../../general/texts/TitleSection";
import TextSection from "../../../general/texts/TextSection";

interface Params {
  project: ProjectModel;
  left?: boolean;
}

const marginX = "mx-5";

const Project = ({ project, left = true }: Params): any => {
  const title = project.name;
  const description = project.text;
  const image = project.image;
  const author = project.author;
  const position = project.position;

  return (
    <div
      className={`flex flex-col items-center md:flex-row ${
        !left && "md:flex-row-reverse"
      } w-full pt-6 1024px:pt-16`}
    >
      <div className="my-4 md:hidden">
        <TitleSection left={left} text={title} lineTop={false} />
      </div>
      <div className={`relative ${left ? "md:pr-16" : "md:pl-16"}`}>
        <div className="w-[10rem] h-[14rem] 1024px:w-[13rem] 1024px:h-[22rem] mb-4 1024px:mb-0">
          <ImageCurve image={image} />
        </div>
        <img
          src={statistics}
          className={`absolute w-24 1024px:w-32 right-[-3rem] ${
            left ? "md:right-0" : "md:left-0"
          } bottom-10`}
          alt=""
        />
      </div>

      <div
        className={`flex flex-col justify-center md:${
          !left && "justify-items-end"
        }`}
      >
        <div className={marginX}>
          <div className="md:block hidden">
            <TitleSection left={true} text={title} lineTop={false} />
          </div>
          <TextSection
            left={true}
            text={description}
            className={`text-xs sm:text-sm 1024px:text-base text-dark-blue md:text-left`}
          />
        </div>
        <div
          className={`hidden md:flex flex-col ${
            !left && "md:justify-items-end"
          } ${marginX}`}
        >
          <span className="text-color-primary text-sm">{author}</span>
          <span className="text-color-light text-xs">{position}</span>
        </div>
      </div>
    </div>
  );
};

export default Project;
