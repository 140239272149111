import React, { useEffect } from "react";
import { RiseLoader } from "react-spinners";

const LoadingIndicator = (): any => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "auto";
    };
  });

  return (
    <div className="fixed top-0 left-0 w-[100vw] h-[100vh] z-50 flex justify-center items-center bg-black bg-opacity-30">
      <RiseLoader size={15} color="#FFB900" />
    </div>
  );
};

export default LoadingIndicator;
