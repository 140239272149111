import React from "react";
import { Icons } from "../../../assets/icons/Icons.svg";
import TagsComponent from "./Tags";
import { Asset } from "../../../interface/blog.interface";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../enums/routes.enum";
import ReactQuillComponent from "./ReactQuillComponent";

interface CardProps {
  category: string;
  date: string;
  title: string;
  description: string | undefined;
  imageUrl: Asset[];
  id: string;
}

const CardBlog = ({
  category,
  date,
  title,
  description,
  imageUrl,
  id
}: CardProps): JSX.Element | null => {
  const url = imageUrl?.length > 0 ? imageUrl[0]?.url : "";
  return (
    <section className="w-full py-[30px] px-[30px] h-auto bg-white rounded-2xl shadow border border-zinc-100 flex flex-col md:flex-row overflow-hidden ">
      <div className=" w-full md:w-2/5 flex-none">
        <img
          className="w-full h-full object-cover rounded-t-lg md:rounded-l-lg md:rounded-t-none shadow"
          src={url}
          alt={title}
        />
      </div>

      <section className="flex-1 px-4 flex flex-col gap-y-4">
        <div className="w-[100px]">
          <TagsComponent
            activeTagProp={category}
            showOnlyActive={true}
            isMultiple={false}
            activeTag={category}
          />
        </div>

        <div className="text-neutral-900 text-sm font-normal leading-[21px]">
          {date}
        </div>
        <div className="text-black font-semibold text-lg">{title}</div>
        <div className="text-neutral-900 text-sm font-normal leading-normal ">
          <ReactQuillComponent
            description={description as any}
            style={"small"}
          />
        </div>
        <div className="mt-auto justify-end items-center gap-1 inline-flex">
          <Link to={`${ROUTES.BLOG}/${id}`}>
            <div className="text-neutral-900 text-sm font-normal underline leading-[21px] cursor-pointer">
              Saber más
            </div>
          </Link>
          <Icons.arrowRight />
        </div>
      </section>
    </section>
  );
};

export default CardBlog;
