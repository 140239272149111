import React from "react";

interface ButtonProps {
  title: string;
  type: "button" | "submit";
  onClick: () => void;
}

const ButtonComponent = ({
  title,
  type,
  onClick
}: ButtonProps): JSX.Element | null => {
  return (
    <button
      className="fixed bottom-0 left-0 bg-primary text-white py-2 rounded-t-md w-full    hover:bg-buttons border-t-3 border-primary"
      type={type}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default ButtonComponent;
