import { useState } from "react";
import Map from "./map/Map";
import Calendar from "./calendar/Calendar";
import Section from "../../general/sections/Section";
import SectionText from "../../general/texts/SectionText";
import PopUpOneButton from "../../general/dialogs/PopUpOneButton";
import ContactForm from "./ContactForm";
import { textContact, titleSection } from "../../../utils/Texts";
import { ROUTES } from "../../../enums/routes.enum";

const Contact = (): any => {
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  return (
    <Section
      addPadding={false}
      name="Contact"
      className="max-w-[100vw]"
      id={ROUTES.CONTACT}
    >
      <div className="w-full bg-page 800px:px-10 1024px:px-28 py-14 max-w-[1532px] mx-auto">
        <SectionText
          title={titleSection.contact.es}
          text={textContact.es}
          left={true}
          className="800px:w-[60%] 1024px:w-[30rem] px-6"
        />
        <div className="flex flex-col 800px:flex-row justify-between">
          <ContactForm />

          <div className="flex flex-col items-center 800px:ml-10 900px:ml-0 900px:items-start 900px:w-22rem 950px:w-27rem 1024px:w-[30rem] mt-20">
            <h3 className="text-center sm:text-left text-dark-blue text-xl sm:text-3xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-bold mb-4">
              Agéndate con nosotros
            </h3>
            <Calendar />
          </div>
        </div>
      </div>
      <Map />
      {showPopUp && (
        <PopUpOneButton
          text={`¡Gracias por enviar la solicitud!\nPronto nos pondremos en contacto`}
          close={() => setShowPopUp(false)}
        />
      )}
    </Section>
  );
};

export default Contact;

// {`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${Credentials.mapsKey}`}
