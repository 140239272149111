import { useEffect, useState } from "react";
import DialogTeamMember from "../dialogTeamMember/DialogTeamMember";
import { TeamMemberModel } from "../../../general/models/TeamMemberModel";
import { textButtons } from "../../../../utils/Texts";
import TeamMemberComponent from "../TeamMember";
import { formatDataUser } from "../../../../utils/funtion";
interface Params {
  list: Array<any>;
}

const Team = ({ list }: Params): any => {
  const [open, setOpen] = useState<TeamMemberModel>();
  const [listUsers, setListUsers] = useState([]);

  
  

  
  const handleClickOpen = (member: TeamMemberModel) => {
    setOpen(member);
  };
  
  let n = 0;
  useEffect(() => {
    setListUsers(formatDataUser(list));
  }, [list]);


  return (
    <div className="800px:flex 800px:justify-center">
      {open && (
        <DialogTeamMember member={open} close={() => setOpen(undefined)} />
      )}
      <div className="flex justify-between overflow-x-auto mt-4 mb-10 800px:flex-wrap 800px:justify-around 800px:w-67em ourServices">
        {listUsers.map((member, i) => {
          n += 1;
          if (listUsers.length % 3 === 0) {
            if (n > 3) n = 1;
          } else {
            if (n > 3) n = 1;
            if (listUsers.length % 2 === 0 && i + 2 > listUsers.length) {
              n = 2;
            } else if (i + 2 > listUsers.length) {
              n = 3;
            }
          }

          return (
            <TeamMemberComponent
              member={member}
              button={textButtons.buttonTeam.es}
              handleClick={() => handleClickOpen(member)}
              positionImage={n}
              key={`person-${i}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Team;
