import { useEffect, useState } from "react";
import { getUserByRol } from "../../../service/action/getAirTable";
import {
  hierarchyLevels,
  textAboutUs,
  titleSection
} from "../../../utils/Texts";
import Section from "../../general/sections/Section";
import SectionText from "../../general/texts/SectionText";
import Team from "./team/Team";
import { DataUser } from "../../../interface/user.interface";
import { ROUTES } from "../../../enums/routes.enum";
import { useHistory } from "react-router-dom";

const AboutUs = (): any => {
  const history = useHistory();

  const [internalAddressUser, setInternalAddressUser] = useState<DataUser[]>(
    []
  );
  const [coordinationUser, setCoordinationUser] = useState<DataUser[]>([]);

  const getUserInternalAddress = async () => {
    try {
      const res = await getUserByRol("Dirección interna");
      if (!res.data.records) return;
      setInternalAddressUser(res.data.records);
    } catch (error) {}
  };

  const getUserCoordination = async () => {
    try {
      const res = await getUserByRol("Coordinación");
      if (!res.data.records) return;
      setCoordinationUser(res.data.records);
    } catch (error) {}
  };

  useEffect(() => {
    getUserInternalAddress();
    getUserCoordination();
  }, []);

  return (
    <Section addBackground={true} name="AboutUs" id={ROUTES.OURS}>
      <SectionText
        text={textAboutUs.es}
        title={titleSection.aboutUs.es}
        left={false}
        className="800px:w-[40rem] self-end px-6"
      />
      <p className="text-dark-blue font-bold text-xl text-center pb-2 800px:pt-20 800px:text-2xl">
        {hierarchyLevels.internalAddress.es}
      </p>
      <div className="flex justify-center items-center pb-6">
        <div className="bg-primary w-40 h-1"></div>
      </div>
      <Team list={internalAddressUser} />
      <p className="mt-10 text-dark-blue font-bold text-xl text-center pb-2 800px:text-2xl">
        {hierarchyLevels.coordination.es}
      </p>
      <div className="flex justify-center items-center pb-6">
        <div className="bg-primary w-40 h-1"></div>
      </div>
      <Team list={coordinationUser} />
    </Section>
  );
};

export default AboutUs;
