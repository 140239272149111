import Section from "../general/sections/Section";

const PrivacyPolicy = (): any => {
  return (
    <Section name="Policy" className="flex justify-center">
      <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        className="w-full min-h-[80vh] mb-10"
        src="https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/legalDocuments%2FPOLI%CC%81TICA%20PARA%20LA%20PROTECCIO%CC%81N%20Y%20TRATAMIENTO%20DE%20DATOS%20PERSONALES-CEPYME%20S.A.S..pdf?alt=media&token=9809d312-0580-41b4-aed0-a5e5e3130e50#zoom=100"
      ></iframe>
    </Section>
  );
};

export default PrivacyPolicy;
