import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import Section from "../../general/sections/Section";
import { Icons } from "../../../assets/icons/Icons.svg";
import { Record } from "../../../interface/blog.interface";
import { CircleLoader } from "react-spinners";
import { useEffect, useState } from "react";
import Cards from "./Cards";
import TagsComponent from "./Tags";
import ContactForm from "../contact/ContactForm";
import ButtonComponent from "./ButtonComponent";
import {
  getDetail,
  getDetailCategory,
  getRelations
} from "../../../service/action/getAirTable";

import ReactQuillComponent from "./ReactQuillComponent";
import { formatAndCapitalizeDate } from "./Blog";
import { ROUTES } from "../../../enums/routes.enum";

export interface BlogDetailParams {
  blogId: string;
}

const BlogDetail = (): JSX.Element | null => {
  const [blogData, setblogData] = useState<Record>();
  const [blogsData, setblogsData] = useState<Record[]>([]);
  const { blogId } = useParams<BlogDetailParams>();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const getBlogsRelations = async (activeTag: string) => {
    try {
      const res = await getRelations(activeTag);

      setblogsData(res.data.records);
    } catch (error) {
      console.error("Error al obtener los blogs:", error);
    }
  };

  const getDetailBlog = async () => {
    try {
      if (!blogId) return;
      const res = await getDetail(blogId);

      setblogData(res.data);
    } catch (error) {
      console.error("Error al obtener los blogs:", error);
    }
    setIsLoading(false);
  };

  const getCategory = async () => {
    const activeTagId = blogData?.fields?.Categorias
      ? blogData?.fields?.Categorias
      : "";
    if (!activeTagId) return;
    const category = await getDetailCategory(activeTagId);

    const activeTag = category?.data?.fields?.Name
      ? category?.data?.fields?.Name
      : "";
    getBlogsRelations(activeTag);
  };

  useEffect(() => {
    getDetailBlog();
  }, [blogId]);

  useEffect(() => {
    getCategory();
  }, [blogData?.fields?.Categorias]);

  const title = blogData?.fields?.Titulo;
  const url =
    blogData?.fields?.Activos && blogData?.fields?.Activos.length > 0
      ? blogData?.fields.Activos[0].url
      : "";

  return (
    <div className="px-10">
      <Section addBackground={false} name={"Blog"}>
        <Breadcrumbs additionalElements={[{ name: title }]} />
        {isLoading && (
          <div className="flex justify-center items-center">
            <CircleLoader size={100} color="#FFC000" />
          </div>
        )}
        <div className="flex w-full">
          <section className="gap-y-[20px] w-[80%]">
            <h2 className="self-stretchself-stretch text-sky-900 text-[20px] md:text-[45px] font-bold font-['Myriad Pro'] leading-[60.20px]">
              {blogData?.fields.Nombre}
            </h2>
            <div className="flex items-center text-gray-500 mt-2">
              <h2>
                {blogData?.fields?.Colaborador?.name} -{" "}
                {formatAndCapitalizeDate(blogData?.createdTime)}
              </h2>
            </div>

            <section className="flex flex-col md:flex-row w-full lg:w-min-[800px] relative rounded-lg mt-6">
              <div className="w-full max-w-xl">
                <img
                  className="w-full md:flex-1 h-auto object-cover rounded-lg  "
                  src={url}
                  alt={blogData?.fields.Titulo}
                />
              </div>
            </section>

            <TagsComponent
              activeTagProp={blogData?.fields?.Categorias}
              showOnlyActive={true}
              isMultiple={false}
              activeTag={blogData?.fields?.Categorias}
            />

            <div className="text-neutral-900 text-sm font-normal leading-normal">
              <ReactQuillComponent
                description={blogData?.fields.Descripcion as any}
                style={"full"}
              />
            </div>
          </section>

          <ContactForm className="bg-white hidden w-full 800px:block max-w-[350px] rounded-xl border mt-4 md:mt-0 md:ml-8 h-auto" />
        </div>

        {blogsData.filter((blog) => blog?.id !== blogId).length > 0 ? (
          <h1 className="self-stretch text-neutral-900 text-[32px] font-normal font-[Myriad_Pro] leading-[41.60px]  mt-4">
            Blogs relacionados
          </h1>
        ) : null}
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          {blogsData
            .filter((blog) => blog?.id !== blogId)
            .map((blog) => (
              <Link
                key={blog?.id}
                to={`${ROUTES.BLOG}/${blog.id}`}
                className="block text-neutral-900 cursor-pointer"
              >
                <Cards
                  key={blog?.id}
                  id={blog?.id}
                  imageUrl={blog?.fields?.Activos}
                  date={formatAndCapitalizeDate(blog?.createdTime)}
                  title={blog?.fields?.Titulo}
                  description={blog?.fields?.Descripcion}
                  tag={blog?.fields?.Categorias}
                />
              </Link>
            ))}
        </div>

        <div className="800px:hidden">
          <ButtonComponent
            title={"¿Estás interesado?"}
            type={"button"}
            onClick={toggleFormVisibility}
          />
        </div>

        <div
          className={`h-[86%] fixed bottom-0  left-0 bg-white px-6 overflow-y-scroll z-50 transition-all  ${
            isFormVisible ? "translate-y-0" : "translate-y-[100%]"
          } `}
        >
          <div
            className="800px:hidden absolute top-0 right-0 mt-2 mr-2 cursor-pointer"
            onClick={toggleFormVisibility}
          >
            <Icons.closeForm />
          </div>
          <ContactForm />
        </div>
      </Section>
    </div>
  );
};

export default BlogDetail;
