import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "../../../assets/icons/Icons.svg";
import { ROUTES } from "../../../enums/routes.enum";

interface BreadcrumbElement {
  name: string | undefined;
  path?: string;
}

interface BreadcrumbsProps {
  additionalElements?: BreadcrumbElement[];
}

const Breadcrumbs = ({
  additionalElements
}: BreadcrumbsProps): JSX.Element | null => {
  const baseElements: BreadcrumbElement[] = [
    {
      name: "Inicio",
      path: `${ROUTES.HOME}`
    },
    {
      name: "Blog",
      path: `${ROUTES.BLOG}`
    }
  ];

  const elements = additionalElements
    ? [...baseElements, ...additionalElements]
    : baseElements;

  return (
    <nav aria-label="breadcrumb" className="flex py-3">
      <ol className="list-reset flex">
        {elements.map((element, index) => (
          <li key={index} className="flex items-center">
            {element.path ? (
              <Link
                to={element.path}
                className="text-dark-blue flex items-center underline leading-[21px] cursor-pointer"
              >
                {element.name}
                {index < elements.length - 1 && (
                  <span className="mx-2">
                    <Icons.arrow />
                  </span>
                )}
              </Link>
            ) : (
              <span className="text-gray-500">{element.name}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
