import React from "react";
import { Icons } from "../../../assets/icons/Icons.svg";
import { Link } from "react-router-dom";
import { Asset } from "../../../interface/blog.interface";
import TagCategory from "./TagCategory";
import { ROUTES } from "../../../enums/routes.enum";
import ReactQuillComponent from "./ReactQuillComponent";

interface BlogCardProps {
  date: string;
  title?: string;
  description?: string;
  imageUrl: Asset[];
  tag: string;
  id: string;
}

const Cards = ({
  date,
  title,
  description,
  tag,
  imageUrl,
  id
}: BlogCardProps): JSX.Element | null => {
  const url = imageUrl?.length > 0 ? imageUrl[0]?.url : "";

  return (
    <div className="w-full bg-white rounded-xl border border-zinc-100 flex flex-col justify-start items-start p-4 min-h-[420px] relative">
      <div className="w-full h-48 rounded-lg overflow-hidden relative">
        <img src={url} alt={title} className="w-full  h-full object-cover" />
        <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-black/60 to-transparent"></div>
        <div className="absolute bottom-0 left-0 p-4">
          <TagCategory category={tag} />
        </div>
      </div>
      {tag && (
        <div className="px-2 py-1 bg-white bg-opacity-20 rounded-[20px] flex justify-center items-center gap-2.5">
          <div className="text-white text-sm">{tag}</div>
        </div>
      )}
      <section className="px-2 flex flex-col justify-start items-start gap-4 w-full">
        <h3 className="text-neutral-900 text-sm">{date}</h3>
        <h3 className="text-neutral-900 text-neutral-900 text-2xl font-normal font-['Myriad Pro'] leading-9 text-2xl">
          {title}
        </h3>
        <h3 className="text-neutral-900 text-base">
          {" "}
          <ReactQuillComponent
            description={description as any}
            style={"small"}
          />
        </h3>
        <h3 className="mt-auto justify-end items-center gap-1 inline-flex absolute bottom-4">
          <Link
            to={`${ROUTES.BLOG}/${id}`}
            className="text-neutral-900 text-sm font-normal underline leading-[21px] cursor-pointer"
          >
            Saber más
          </Link>
          <Icons.arrowRight />
        </h3>
      </section>
    </div>
  );
};

export default Cards;
