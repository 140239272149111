import LeadingText from "./LeadingText";
interface Params {
  left?: boolean;
  text: string;
  lineTop?: boolean;
}

const TitleSection = ({ text, left = true, lineTop = true }: Params): any => {
  return (
    <div className="flex flex-col-reverse 800px:flex-col">
      {lineTop && (
        <div
          className={`flex justify-center ${
            left ? "800px:justify-start" : "800px:justify-end"
          }`}
        >
          <div className="bg-line-title my-2 h-1 w-16"></div>
        </div>
      )}
      <div className="text-dark-blue text-xl sm:text-3xl lg:text-4xl xl:text-4xl 2xl:text-6xl font-bold">
        <h2
          className={`flex justify-center text-center ${
            left ? "800px:justify-start" : "800px:justify-end"
          }`}
        >
          <LeadingText text={text} />
        </h2>
      </div>
    </div>
  );
};

export default TitleSection;
