export class ServiceModel {
  image: string;
  title: string;
  text: string;
  description: string;
  titleServicesOffered: string;
  servicesOffered: string;

  constructor(
    image: string,
    title: string,
    text: string,
    description: string,
    titleServicesOffered: string,
    servicesOffered: string
  ) {
    this.image = image;
    this.title = title;
    this.text = text;
    this.description = description;
    this.titleServicesOffered = titleServicesOffered;
    this.servicesOffered = servicesOffered;
  }
}
