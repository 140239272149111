export class ProjectModel {
  image: string;
  name: string;
  text: string;
  author: string;
  position: string;

  constructor(
    image: string,
    name: string,
    text: string,
    author: string,
    position: string
  ) {
    this.image = image;
    this.name = name;
    this.text = text;
    this.author = author;
    this.position = position;
  }
}
