import { useState } from "react";
import { projectsDescription } from "../../../utils/Texts";
import PrincipalButton from "../../general/buttons/PrincipalButton";
import { ProjectModel } from "../../general/models/ProjectModel";
import Section from "../../general/sections/Section";
import SectionText from "../../general/texts/SectionText";
import Project from "./project/Project";
import { ROUTES } from "../../../enums/routes.enum";

const projects = [
  new ProjectModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/projects%2Fcaso_1.png?alt=media&token=fcb27c3d-f7a9-439c-85e3-a96f6d2425e0",
    "OPHARM",
    `Diagnóstico de Internacionalización y asesoría para la Negociación de Distribución Internacional.\n
    Los profesionales de Cepyme realizaron un diagnóstico legal, financiero y de comercio exterior entregándonos un plan de trabajo que nos permitiera pensar en la distribución de nuestros medicamentos oftalmológicos en Latinoamérica, nos ayudaron a identificar los mejores mercados y luego a negociar con un distribuidor explusivo para la comercialización de varios de nuestros productos a su país.\n`,
    "Natalia Cañon",
    "Subgerente"
  ),
  new ProjectModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/projects%2Fcaso_2.png?alt=media&token=adfbcd3f-cd1d-434b-b955-1a226618d025",
    "ADVANCE SCIENTIFIC COLOMBIA",
    `Negociación y contratos internacionales con importantes laboratorios a nivel mundial.\n
      Para advance Scientific de Colombia sentirse apoyado por un equipo multidisiciplinario como CEPYME le genera tranquilidad, porque estamos seguros que ese crecimiento que no está en nuestra organización lo vamos a tener con el talento de CEPYME.\n
      Esto nos disminuye la incertidumbre ante el riesgo: Financiero, Juridico, Laboral y Comercial. Que está siempre atento a las dudas e inquietudes que surgen diariamente en la operación de una compañía.\n`,
    "Gloria González",
    "Gerente General"
  ),
  new ProjectModel(
    "https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/projects%2Fcaso_3.png?alt=media&token=4b160b0a-e8b3-4005-b4f2-da57b44dafae",
    "SINOTRANS QIANTANG",
    `Cepyme nos brinda los servicios de outsourcing de asesoría legal, contable y financiera, dándonos soporte en todos los aspectos laborales, comerciales, aduaneros, cambiarios, tributarios y financieros requeridos por nuestra empresa.\n`,
    "Andrés Felipe Sánchez",
    "Gerente General"
  )
];

const Projects = (): any => {
  const [selected, setSelected] = useState<number>(0);

  return (
    <Section
      addBackground={true}
      name="Projects"
      className="px-4"
      id={ROUTES.SUCCESS_STORIES}
    >
      <SectionText
        title={projectsDescription.title.es}
        text={projectsDescription.description.es}
        className="800px:w-[40rem]"
      />
      <div className="w-full 600px:w-[60%] 600px:mx-auto grid grid-cols-2  mt-8 gap-2 gap-y-6 md:hidden">
        {projects.map((project, i) => {
          return (
            <div
              className={`m-auto ${i !== selected && "opacity-50"}`}
              key={`button-project-${i}`}
            >
              <PrincipalButton
                text={`Caso ${i + 1}`}
                handleClick={() => setSelected(i)}
              />
            </div>
          );
        })}
      </div>
      {projects.map((project, i) => {
        return (
          <div
            className={selected !== i ? "hidden md:flex" : "flex"}
            key={`project-${i}`}
          >
            <Project project={project} left={i % 2 === 0} />
          </div>
        );
      })}
    </Section>
  );
};

export default Projects;
