import axios from 'axios';

export enum RESPONSE_TYPES {
  OK = 'OK',
  ERROR = 'ERROR',
}




export type StandardResponse<T = any> = {
  responseType: RESPONSE_TYPES;
  data?: any;
  error?: string;
};



const API_KEY = process.env.REACT_APP_API_KEY;
const BASE_ID = process.env.REACT_APP_BASE_ID;

 export const TABLE_NAME = process.env.REACT_APP_TABLE_NAME;

const AIRTABLE_BASE_URL = `https://api.airtable.com/v0/${BASE_ID}`;

/**
 * Realiza una solicitud HTTP GET.
 * @param endpoint - El punto final específico de la solicitud, ej. "posts" si tu URL es algo como ".../Blog/posts".
 * @param params - Los parámetros de la solicitud como un objeto.
 * @returns Una promesa que se resuelve con la respuesta de la solicitud.
 */
export const getData = async <T>(
  endpoint: string,
  params: Record<string, any> = {}
): Promise<StandardResponse<T>> => {
  try {
    const response = await axios.get(`${AIRTABLE_BASE_URL}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${API_KEY}`,
      },
      params: {
       
        ...params, 
      },
    });

    return { responseType: RESPONSE_TYPES.OK, data: response.data };
  } catch (error) {
    console.error('Error fetching data:', error);
    return { responseType: RESPONSE_TYPES.ERROR, error: 'Failed to fetch data' };
  }
};
