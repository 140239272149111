import TitleSection from "../../general/texts/TitleSection";
import logoEchaiz from "../../../assets/allies/Echaiz.png";
import logoGsh from "../../../assets/allies/gsh.png";
import logoColperu from "../../../assets/allies/Colperu.png";
import logoSinotrans from "../../../assets/allies/Sinotrans.png";
import logoBrace from "../../../assets/allies/Brace.png";
import Section from "../../general/sections/Section";

const OurAllies = (): any => {
  return (
    <Section className="items-center my-20" name="Allies">
      <TitleSection left={true} text="Nuestros Aliados" />
      <div className="flex justify-around items-center flex-wrap sm:mt-10">
        <a
          href="https://bracedevelopers.com/"
          className="flex justify-center items-center mx-8 w-28 800px:w-auto"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logoBrace} alt="" className="w-full" />
        </a>
        <a
          href="https://www.echaiz.com/"
          className="flex justify-center items-center mx-8 w-28 800px:w-auto"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logoEchaiz} alt="" className="w-full" />
        </a>

        <a
          href="https://www.gsh.com.co/"
          className="flex justify-center items-cente mx-8 w-28 800px:w-autor"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logoGsh} alt="" className="w-full" />
        </a>
        <a
          href="https://colperu.com/wps/"
          className="flex justify-center items-center mx-8 w-28 800px:w-auto"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logoColperu} alt="" className="w-full" />
        </a>
        <a
          href="http://www.sinotrans.com/col/col3771/"
          className="flex justify-center items-center mx-8 w-28 800px:w-auto"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logoSinotrans} alt="" className="w-full" />
        </a>
      </div>
    </Section>
  );
};

export default OurAllies;
