import logo from "../../../assets/logo_footer.svg";
import linkedinIcon from "../../../assets/icons/linkedin.svg";
import facebookIcon from "../../../assets/icons/facebook.svg";
import phoneIcon from "../../../assets/icons/phone.svg";
import mailIcon from "../../../assets/icons/mail.svg";
import { Link as LinkScroll } from "react-scroll";
import { Link, useLocation } from "react-router-dom";
import whatsapp from "../../../assets/general/whatsapp.svg";

const Footer = (): any => {
  const location = useLocation().pathname;

  const isHome = location === "/";
  return (
    <div className="bg-footer pt-14 text-white text-sm flex flex-col items-center">
      <div className="max-w-[1532px] w-full">
        <div className="grid grid-cols-1 800px:grid-cols-3 px:10 800px:px-28">
          <div className="flex flex-col items-center">
            <img
              src={logo}
              alt="CEPYME"
              className=" mb-10 w-[6rem] 800px:mr-[7rem]"
            />
            <div className="font-bold text-base mb-6 800px:w-[13rem]">
              Información de contacto
              <div className="w-[1.6rem] h-[2px] bg-white" />
            </div>
            <a
              href="tel:+573112762770"
              target="noreferrer"
              className="flex items-center mb-4 800px:w-[13rem] hover:underline"
            >
              <img src={phoneIcon} alt="" className="w-[1.5em] mr-4" /> + 57 311
              276 2770
            </a>
            <a
              href="mailto:dir.comercial@cepyme.com.co"
              target="noreferrer"
              className="flex items-center mb-4 800px:w-[13rem] hover:underline"
            >
              <img src={mailIcon} alt="" className="w-[1.5em] mr-4" />
              dir.comercial@cepyme.com.co
            </a>
          </div>
          <div className="flex flex-col items-center">
            <div className="font-bold text-base mb-6 800px:w-[6rem]">
              Sitemap
              <div className="w-[1.6rem] h-[2px] bg-white" />
            </div>
            {!isHome ? (
              <Link
                className="mb-4 800px:w-[6rem] cursor-pointer hover:underline"
                to="/"
              >
                Servicios
              </Link>
            ) : (
              <LinkScroll
                to="Services"
                className="mb-4 800px:w-[6rem] cursor-pointer hover:underline"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Servicios
              </LinkScroll>
            )}
            {!isHome ? (
              <Link
                className="mb-4 800px:w-[6rem] cursor-pointer hover:underline"
                to="/"
              >
                Nosotros
              </Link>
            ) : (
              <LinkScroll
                to="AboutUs"
                className="mb-4 800px:w-[6rem] cursor-pointer hover:underline"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Nosotros
              </LinkScroll>
            )}
            {!isHome ? (
              <Link
                className="mb-4 800px:w-[6rem] cursor-pointer hover:underline"
                to="/"
              >
                Casos de éxito
              </Link>
            ) : (
              <LinkScroll
                to="Projects"
                className="mb-4 800px:w-[6rem] cursor-pointer hover:underline"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Casos de éxito
              </LinkScroll>
            )}
          </div>
          <div className="flex flex-col items-center">
            <div className="font-bold text-base mb-6 800px:w-[12rem]">
              Otros enlaces
              <div className="w-[1.6rem] h-[2px] bg-white" />
            </div>
            <Link
              to="/politica-datos"
              className="mb-4 800px:w-[12rem] hover:underline"
              target="_blank"
            >
              Política de tratamiento de datos
            </Link>
            <Link
              to="/preguntas"
              className="mb-4 800px:w-[12rem] hover:underline"
            >
              Preguntas frecuentes
            </Link>
          </div>
        </div>
        <div className="flex items-center flex-col">
          <div className="flex justify-cebnter items-center mb-6">
            <a
              href="https://www.linkedin.com/"
              target="noreferrer"
              className="mx-4 w-6"
            >
              <img src={linkedinIcon} alt="CEPYME" />
            </a>
            <a
              href="https://www.facebook.com/cepymeconsultores"
              target="noreferrer"
              className="mx-4 w-6"
            >
              <img src={facebookIcon} alt="CEPYME" />
            </a>
          </div>
          <div className="mb-2">@copyright 2021.</div>
          <div>Todos los derechos reservados</div>
        </div>
      </div>
      <div className="px-14 py-4 mt-4 bg-white w-full flex justify-center">
        <div className="w-full text-center text-xs text-color-default max-w-[1532px]">
          <b>AVISO DE PRIVACIDAD</b>
          <br />
          En cumplimiento de la Ley 1581 de 2012 y el Decreto Único
          Reglamentario 1074 de 2015, con el fin de garantizar un manejo
          adecuado de los Datos Personales que hayan sido o sean recolectados,
          el CENTRO DE ACELERACIÓN E INTERNACIONALIZACIÓN DE PYMES S.A.S.
          (CEPYME S.A.S.) le informa que ha implementado la POLÍTICA PARA LA
          PROTECCIÓN Y TRATAMIENTO DE DATOS PERSONALES disponible en el sitio
          web &nbsp;
          <Link to="/politica-datos" target="_blank" className="underline">
            <b>https://cepyme.com.co/politica-datos</b>
          </Link>
        </div>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=+573112762770"
        target="_blank"
        rel="noreferrer"
        className="floating-button"
      >
        <div className="flex flex-row px-2">
          <img src={whatsapp} alt="" />
          <div className="text-container hidden 800px:flex 800px:flex-col 800px:justify-center">
            <div className="w-48 text-white px-5 text-sm">Escribenos</div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Footer;
