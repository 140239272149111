import React from 'react';

export const Icons = {
   arrow: React.memo(() => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#021B5A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M8.91 19.92l6.52-6.52c.77-.77.77-2.03 0-2.8L8.91 4.08"
      ></path>
    </svg>
  ), ),
  arrowRight: React.memo(() => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
  >
    <path
      fill="#171717"
      fillRule="evenodd"
      d="M13.9 5.9a.75.75 0 011.06 0l6.07 6.07a.75.75 0 010 1.06l-6.07 6.07a.75.75 0 11-1.06-1.06l4.79-4.79H3.5a.75.75 0 010-1.5h15.19L13.9 6.96a.75.75 0 010-1.06z"
      clipRule="evenodd"
    ></path>
  </svg>
  ),),
  facebook: React.memo(() => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#0541D6"
      d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95z"
    ></path>
  </svg>
  ),),
  instagram: React.memo(() => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#0541D6"
      d="M13.029 2.001c.73-.003 1.459.005 2.189.022l.194.007c.224.008.445.018.712.03 1.064.05 1.79.218 2.427.465.66.254 1.216.598 1.772 1.154.508.5.901 1.104 1.153 1.771.247.637.415 1.364.465 2.428.012.266.022.488.03.712l.006.194c.017.73.025 1.459.023 2.188v2.056c.003.73-.005 1.46-.022 2.189l-.006.194c-.008.224-.018.445-.03.712-.05 1.064-.22 1.79-.466 2.427a4.885 4.885 0 01-1.153 1.772c-.5.508-1.105.902-1.772 1.153-.637.247-1.363.415-2.427.465-.267.012-.488.022-.712.03l-.194.006c-.73.018-1.46.025-2.19.023l-.745.001h-1.31c-.73.003-1.459-.005-2.188-.023l-.194-.006a60.403 60.403 0 01-.712-.03c-1.064-.05-1.79-.22-2.428-.466a4.89 4.89 0 01-1.771-1.153 4.904 4.904 0 01-1.154-1.772c-.247-.637-.415-1.363-.465-2.427a74.175 74.175 0 01-.03-.712l-.005-.194c-.019-.73-.027-1.46-.025-2.189v-2.056c-.003-.73.004-1.459.022-2.188l.007-.194c.008-.224.018-.446.03-.712.05-1.065.218-1.79.465-2.428A4.88 4.88 0 013.68 3.68a4.897 4.897 0 011.77-1.155c.638-.247 1.363-.415 2.428-.465l.712-.03.194-.005A79.05 79.05 0 0110.973 2l2.056.001zm-1.028 5A5 5 0 1012 17a5 5 0 000-9.999zm0 2A3 3 0 1112 15a3 3 0 010-6v.001zm5.25-3.5a1.25 1.25 0 000 2.498 1.25 1.25 0 000-2.5v.002z"
    ></path>
  </svg>
  ),),
  twitter: React.memo(() => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0541D6"
        d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 01-1.93.07 4.28 4.28 0 004 2.98 8.521 8.521 0 01-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z"
      ></path>
    </svg>
  ),),
  whatsApp: React.memo(() => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0541D6"
        d="M20.925 6.36C17.79 1.5 11.37.06 6.405 3.075 1.56 6.09 0 12.66 3.135 17.505l.255.39-1.05 3.93 3.93-1.05.39.255c1.695.915 3.54 1.44 5.37 1.44 1.965 0 3.93-.525 5.625-1.575 4.845-3.15 6.285-9.585 3.27-14.565v.03zM18.18 16.47c-.525.78-1.185 1.305-2.1 1.44-.525 0-1.185.255-3.795-.78-2.22-1.05-4.065-2.76-5.37-4.725-.78-.915-1.185-2.1-1.305-3.285 0-1.05.39-1.965 1.05-2.625.255-.255.525-.39.78-.39h.66c.255 0 .525 0 .66.525.255.66.915 2.235.915 2.37.135.135.075 1.14-.525 1.71-.33.375-.39.39-.255.66.525.78 1.185 1.575 1.83 2.235.78.66 1.575 1.185 2.49 1.575.255.135.525.135.66-.135.135-.255.78-.915 1.05-1.185.255-.255.39-.255.66-.135l2.1 1.05c.255.135.525.255.66.39.135.39.135.915-.135 1.305h-.03z"
      ></path>
    </svg>
  ),),
  closeForm: React.memo(() => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#171717"
      fillRule="evenodd"
      d="M1.25 12C1.25 6.086 6.086 1.25 12 1.25S22.75 6.086 22.75 12 17.914 22.75 12 22.75 1.25 17.914 1.25 12zM12 2.75c-5.086 0-9.25 4.164-9.25 9.25s4.164 9.25 9.25 9.25 9.25-4.164 9.25-9.25S17.086 2.75 12 2.75zM8.64 8.64a.75.75 0 011.06 0l2.3 2.3 2.3-2.3a.75.75 0 111.06 1.06l-2.3 2.3 2.3 2.3a.75.75 0 11-1.06 1.06l-2.3-2.3-2.3 2.3a.75.75 0 01-1.06-1.06l2.3-2.3-2.3-2.3a.75.75 0 010-1.06z"
      clipRule="evenodd"
    ></path>
  </svg>
  ),)
};


Icons. arrow.displayName = 'MenuIcon';
Icons. arrowRight.displayName = 'Icon';
Icons. facebook.displayName = 'facebook';
Icons. instagram.displayName = 'instagram';
Icons. twitter.displayName = 'twitter';
Icons. whatsApp.displayName = 'whatsApp';
Icons. closeForm.displayName = 'closeForm';

