import React, { useState, useEffect } from "react";
import { Category } from "../../../interface/blog.interface";
import { getAllCategories } from "../../../service/action/getAirTable";

interface Tag {
  id: string;
  name: string;
}

export const formatDataFromBackend = (records: Category[]): any => {
  const formattedData = records.map(({ id, fields }) => {
    if (id && fields) {
      const tagName = fields.Name;
      return {
        id: id,
        name: tagName
      };
    }
    return null;
  });
  return formattedData.filter((record) => record !== null);
};

interface TagsComponentProps {
  activeTagProp?: string;
  showOnlyActive?: boolean;
  setActiveTag?: React.Dispatch<React.SetStateAction<string>>;
  activeTag?: string;
  isMultiple: boolean;
}

const TagsComponent = ({
  activeTagProp,
  showOnlyActive,
  setActiveTag,
  activeTag,
  isMultiple
}: TagsComponentProps): JSX.Element | null => {
  const [tags, setTags] = useState<Tag[]>([{ id: "1", name: "Todos" }]);

  const getCategory = async () => {
    const categories = await getAllCategories();
    if (!categories.data) return;
    const tagsFormated = formatDataFromBackend(categories.data.records);
    if (!tagsFormated) return;
    setTags((tags) => [...tags, ...tagsFormated]);
  };

  const handleClick = (tagName: string) => {
    setActiveTag && setActiveTag(tagName);
  };

  useEffect(() => {
    getCategory();
  }, []);
  const getTag = (activeTag: string) => {
    const tag = tags.find((tag) => tag.id == activeTag);
    if (!tag) return "";
    return tag.name;
  };

  return (
    <div className="w-full h-[103px] flex flex-col justify-center items-start ">
      <div className="justify-center items-start gap-2 inline-flex">
        {isMultiple ? (
          tags
            .filter((tag) => !showOnlyActive || tag.name === activeTag)
            .map((tag) => (
              <div
                key={tag.id}
                className={`px-4 py-2 cursor-pointer ${
                  activeTag === tag.name
                    ? "bg-[#18386C] text-white border border-black"
                    : "border border-black text-neutral-900"
                } rounded-[20px] justify-center items-center gap-2.5 flex`}
                onClick={() => handleClick(tag.name)}
              >
                <div className="text-sm font-normal font-[Myriad_Pro] leading-[21px]">
                  {tag.name}
                </div>
              </div>
            ))
        ) : (
          <div
            className={`px-4 py-2 cursor-pointer bg-[#18386C] text-white rounded-[20px] justify-center items-center gap-2.5 flex`}
          >
            <div className="text-sm font-normal font-[Myriad_Pro] leading-[21px]">
              {getTag(activeTag || "")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TagsComponent;
