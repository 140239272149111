import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import GeneralContext from "./screens/general/generalContext/GeneralContext";
import LoadingIndicator from "./screens/general/loadingIndicator/LoadingIndicator";
import "./styles/main.sass";
import "./styles/tailwind.css";
import { getLanguage, changeLanguage } from "./proxy/LocalStorage";
import Home from "./screens/home/Home";
import FrequentQuestions from "./screens/questions/FrequentQuestions/FrequentQuestions";
import Header from "./screens/general/header/Header";
import Footer from "./screens/general/footer/Footer";
import PrivacyPolicy from "./screens/legalDocuments/PrivacyPolicy";
import BlogDetail from "./screens/home/blog/BlogDetail";
import Blog from "./screens/home/blog/Blog";
import { ROUTES } from "./enums/routes.enum";

const App = (): any => {
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<string>(getLanguage());

  const contextData = {
    runLoading: () => setLoading(true),
    stopLoading: () => setLoading(false),
    setLanguage: (value: string) => {
      setLanguage(value);
      changeLanguage(value);
    },
    language: language
  };

  return (
    <GeneralContext.Provider value={contextData}>
      <div className="bg-page pt-24">
        <BrowserRouter>
          <Switch>
          <Route path={ROUTES.DETAIL_OF_BLOG} component={BlogDetail} />
          <Route path={ROUTES.BLOG}  component={Blog } />
            <Route path={ROUTES.QUESTIONS}  component={FrequentQuestions} />
            <Route path={ROUTES.DATA_POLICY}  component={PrivacyPolicy} />
            <Route path={ROUTES.HOME} component={Home} />
          </Switch>
          <Header /> 
          <Footer />
        </BrowserRouter>
      </div>
      {loading && <LoadingIndicator />}
    </GeneralContext.Provider>
  );
};

export default App;
