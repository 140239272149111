import Projects from "./projects/Projects";
import AboutUs from "./aboutUs/AboutUs";
import Banner from "./banner/Banner";
import Contact from "./contact/Contact";
import OurAllies from "./OurAllies/OurAllies";
import OurServices from "./ourServices/OurServices";


const Home = (): any => {
  return (
    <div>
      <Banner />
      <OurServices />
      <Projects />
      <OurAllies />
      <AboutUs />
      <Contact />
    </div>
  );
};

export default Home;
