// import { useState, useCallback, memo } from "react";
// import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
// import Credentials from "./Credentials";

// const containerStyle = {
//   width: "100vw",
//   height: "400px"
// };

// const center = {
//   lat: 4.710989,
//   lng: -74.07209
// };

const Map = (): any => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1388.8308375234835!2d-74.06282296724851!3d4.680861394031423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9aeee0e58289%3A0x1e7b20b37246dc7f!2sCra.%2049%20%2391-75%2C%20Bogot%C3%A1!5e0!3m2!1ses-419!2sco!4v1628880380252!5m2!1ses-419!2sco"
      width="100%"
      height="400"
      style={{ border: 0 }}
      // allowFullScreen="false"
      loading="lazy"
    ></iframe>
  );

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyB2k04P8bL6u9Gd5DeGfeZqNvvmr07kRtA"
  // });

  // return isLoaded ? (
  //   <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
  //     {/* Child components, such as markers, info windows, etc. */}
  //     <></>
  //   </GoogleMap>
  // ) : (
  //   <></>
  // );
};

export default Map;
// export default memo(Map);
