import LeadingText from "../texts/LeadingText";
import closeIcon from "../../../assets/close-icon-blue.svg";
import PrincipalButton from "../buttons/PrincipalButton";

interface Params {
  close: () => void;
  text: string;
}

const PopUpOneButton = ({ text, close }: Params): any => {
  return (
    <div className="fixed bg-black bg-opacity-25 w-screen h-screen -left-px z-50 top-0 flex flex-col items-center justify-center">
      <div
        className="absolute top-0 bg-blue flex flex-col items-center justify-end w-screen h-screen"
        onClick={close}
      />
      <div className="flex flex-col items-center bg-white relative w-72 h-96 400px:w-3/4 800px:w-[25rem] 800px:h-auto min-h-[15rem]">
        <div className="flex flex-col h-full w-full relative">
          <div>
            <div className="bg-primary absolute top-0 left-0 w-20 h-1 800px:w-20 800px:h-3 "></div>
            <div className="bg-primary absolute top-0 left-0 w-1 h-20 800px:w-3 800px:h-20"></div>
          </div>

          <div className="flex justify-end items-center">
            <img
              src={closeIcon}
              alt=""
              className="absolute top-0 w-5 800px:w-8 mt-3 mr-3 cursor-pointer"
              onClick={close}
            />
          </div>
          <div className="flex flex-col justify-center items-center h-full m-10 mt-14">
            <LeadingText text={text} className="text-lg text-center" />
            <div className="h-8" />
            <PrincipalButton text="Vale" handleClick={close} />
          </div>
          <div>
            <div className="bg-primary absolute bottom-0 right-0 w-20 h-1 800px:w-20 800px:h-3"></div>
            <div className="bg-primary absolute bottom-0 right-0 w-1 h-20 800px:w-3 800px:h-20"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpOneButton;
