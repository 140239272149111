import { ApiResponse, Category } from "../../interface/blog.interface";
import { APIResponseUser } from "../../interface/user.interface";
import { getData } from "../httpRequests";

export const getAllBlog = async (
  activeTag: string,
  offset: string | null
): Promise<any> => {
  const recordsPerPage = process.env.REACT_APP_RECORS_PER_PAGE;

  const endpoint =
    activeTag !== "Todos"
      ? `${"Blog"}?filterByFormula=FIND('${activeTag}',Categorias)&sort%5B0%5D%5Bfield%5D=Fecha&sort%5B0%5D%5Bdirection%5D=desc&pageSize=${recordsPerPage}${
          offset ? `&offset=${offset}` : ""
        }`
      : `${"Blog"}?sort%5B0%5D%5Bfield%5D=Fecha&sort%5B0%5D%5Bdirection%5D=desc&pageSize=${recordsPerPage}${
          offset ? `&offset=${offset}` : ""
        }`;
  return await getData<ApiResponse>(`${endpoint}`);
};

export const getRelations = async (activeTag: string | null): Promise<any> => {
  const params = activeTag
    ? { filterByFormula: `FIND('${activeTag}',Categorias)`, maxRecords: 3 }
    : { maxRecords: 3 };

  return await getData<ApiResponse>(`${"Blog"}`, params);
};

export const getDetail = async (blogId: string): Promise<any> => {
  return await getData<ApiResponse>(`${"Blog"}/${blogId}`);
};

export const getDetailCategory = async (categoryId: string): Promise<any> => {
  return await getData<Category>(`${"Categorias"}/${categoryId}`);
};
export const getAllCategories = async (): Promise<any> => {
  return await getData<Category[]>("Categorias");
};

export const getAllUser = async (): Promise<any> => {
  return await getData<APIResponseUser[]>("Usuarios");
};

export const getUserByRol = async (rol: string): Promise<any> => {
  const endpoint = `${"Usuarios"}?filterByFormula=FIND('${rol}',Rol)`;

  return await getData<APIResponseUser>(`${endpoint}`);
};
