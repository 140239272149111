export enum ROUTES {
  HOME = "/",
  BLOG = "/blog",
  SERVICES = "/servicios",
  OURS = "/nosotros",
  SUCCESS_STORIES = "/casos-de-exito",
  CONTACT = "/contacto",
  DETAIL_OF_BLOG = "/blog/:blogId",
  QUESTIONS = "/preguntas",
  DATA_POLICY = "/politica-datos"
}
