import LeadingText from "../../../general/texts/LeadingText";
import { TeamMemberModel } from "../../../general/models/TeamMemberModel";
import check from "../../../../assets/check.svg";
import closeIconBlue from "../../../../assets/close-icon-blue.svg";

interface ParamsMember {
  member: TeamMemberModel;
  close: () => void;
  additionalSection?: boolean;
}

const DialogTeamMember = ({ member, close }: ParamsMember): any => {
  return (
    <div className="fixed bg-black bg-opacity-25 w-screen h-screen -left-px z-50 top-0 flex flex-col items-center justify-center">
      <div
        className="absolute top-0 bg-blue flex flex-col items-center justify-end w-screen h-screen"
        onClick={close}
      />
      <div className="flex flex-col items-center h-30rem bg-white relative w-72 400px:w-3/4 800px:w-38rem 800px:h-38rem">
        <div className="flex flex-col 1024px:flex-row h-full w-full relative">
          <div className="overflow-y-auto my-5 1024px:my-0 1024px:overflow-y-hidden flex flex-col scrollbar-styles">
            <div className="flex justify-end items-center">
              <img
                src={closeIconBlue}
                alt=""
                className="absolute w-4 top-0 800px:w-8 mt-3 mr-3 cursor-pointer"
                onClick={close}
              />
            </div>
            <div className="flex flex-col 1024px:flex-row 1024px:h-full">
              <div className="flex flex-col-reverse items-center">
                <img
                  className="object-cover my-4 1024px:my-0 w-28 h-full 800px:w-17rem"
                  src={member.image}
                />
                <div className="flex flex-col 1024px:hidden">
                  <p className="text-center font-bold mt-5 text-sm 400px:mt-4 800px:text-base mx-auto 800px:mt-8">
                    {member.name}
                  </p>
                  <div className="flex justify-center items-center">
                    <div className="bg-primary h-1 w-16 800px:w-24"></div>
                  </div>
                </div>
              </div>
              <div className="pt-12 pb-5 flex flex-col 1024px:w-96">
                <div className="bg-transparent 1024px:overflow-y-auto h-full px-4 scrollbar-styles">
                  <p className="hidden 1024px:flex 1024px:justify-center 1024px:text-center 1024px:font-bold 1024px:text-base 1024px:mx-auto">
                    {member.name}
                  </p>
                  <div className=" hidden 1024px:flex 1024px:justify-center 1024px:items-center">
                    <div className="bg-primary h-1 w-16 800px:w-24"></div>
                  </div>
                  <p className="text-center text-color-default text-xs mt-1 mx-auto 400px:mt-3 800px:mt-2 800px:text-base">
                    {member.text}
                  </p>
                  <p className="text-center pb-5 pt-2 text-xs 1024px:px-5 1024px:text-left mt-1 px-8 400px:mt-3 500px:px-3 600px:px-5 700px:px-6 800px:px-8 w-full 800px:w-full 800px:text-sm 800px:mt-5">
                    <LeadingText text={member.description} />
                  </p>
                  {member.skills ? (
                    <p className="text-center 1024px:text-left text-xs px-2 mt-1 400px:mt-3 500px:px-3 600px:px-5 700px:px-6 800px:text-base font-bold 800px:px-8 w-full 800px:mx-auto 800px:mt-5">
                      Habilidades:
                    </p>
                  ) : (
                    ""
                  )}
                  {member.skills ? (
                    <p className="text-start text-xs px-2 mt-1 400px:mt-3 500px:px-3 600px:px-5 700px:px-6 800px:text-base 800px:px-8 800px:w-full 800px:mt-2">
                      {member.skills.split(";").map((skill, i) => {
                        return (
                          <div
                            key={`skill-${i}`}
                            className="flex justify-start items-start mb-3"
                          >
                            <img src={check} alt="" className="pr-3" />
                            <LeadingText text={skill} />
                          </div>
                        );
                      })}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primary absolute top-0 left-0 w-20 h-1 800px:w-48 800px:h-3 "></div>
        <div className="bg-primary absolute top-0 left-0 w-1 h-20 800px:w-3 800px:h-48"></div>

        <div className="bg-primary absolute bottom-0 right-0 w-20 h-1 800px:w-48 800px:h-3"></div>
        <div className="bg-primary absolute bottom-0 right-0 w-1 h-20 800px:w-3 800px:h-48"></div>
      </div>
    </div>
  );
};

export default DialogTeamMember;
