import React from "react";
import playIcon from "../../../assets/playIcon.svg";
import patternHeader from "../../../assets/Pattern-circle-header.svg";
import ImageCurve from "../../general/images/ImageCurve";
import { Link } from "react-scroll";
import { bannerTexts, textButtons } from "../../../utils/Texts";
import LeadingText from "../../general/texts/LeadingText";
import { ROUTES } from "../../../enums/routes.enum";

const Banner: React.FC = () => {
  return (
    <div
      className="w-full h-full relative bottom-3 flex justify-center  mb-8 md:mb-20 bg-gradient-banner"
      id={"Home"}
    >
      <div className="w-full h-full font-myriad flex flex-col lg:flex-row max-w-[1500px] ">
        <div className="w-full px-6 1024px:w-[75%] bg-white py-10 1024px:py-28 space-y-5 1024px:rounded-br-200px">
          <div className="space-y-5 w-full 1024px:w-4/5 flex flex-col justify-center m-auto">
            <div className="w-full space-y-10 flex flex-col items-center 1024px:items-start">
              <h1 className="text-h1 sm:text-h1-tablet 1024px:h1-desktop w-full 1024px:w-8/12 text-center 1024px:text-left leading-[1.1] text-dark-blue font-bold">
                <LeadingText text={bannerTexts.title.es} />
              </h1>
              <p className="w-full text-lg sm:w-[70%] 1024px:w-1/2 text-center 1024px:text-left lg:text-xl text-color-default font-normal">
                {bannerTexts.description.es}
              </p>
              <Link
                className="btn-primary btn-secondary cursor-pointer"
                to="Services"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                {textButtons.buttonBanner.es}
              </Link>
            </div>
            <div className="hidden pt-8 px-10 1024px:px-0 md:flex w-full justify-center 1024px:justify-start items-center gap-5">
              <p className="text-xl w-[40%] text-center 1024px:text-left lg:text-2xl text-dark-blue 1024px:w-1/2">
                {bannerTexts.textVideo.es}
              </p>
              <div className="w-20 h-20 bg-primary hover:bg-secondary-opacity transition-all duration-300 hover:scale-105   rounded-full p-2 cursor-pointer">
                <div className="w-[65px] h-[65px] bg-primary hover:bg-secondary transition-all duration-300 hover:scale-105 rounded-full flex items-center justify-center flex-col">
                  <a
                    href={bannerTexts.urlVideo.es}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-6 h-6 hover:scale-105 transition duration-300"
                      src={playIcon}
                      alt="play video"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-10 500px:px-16 500px:py-20 700px:py-30 700px:px-28 1024px:w-[25%] relative py-8 pb-14 rounded-br-70px bg-white 1024px:bg-transparent">
          <div className="absolute left-7 top-4 w-[38%] 1024px:hidden md:top-5 w-full">
            <img src={patternHeader} alt="" />
          </div>
          <div className="absolute right-6 bottom-10 w-[38%] 1024px:w-[15rem] 1024px:relative  1024px:right-[22rem]">
            <img src={patternHeader} alt="" />
          </div>
          <div className="h-[20rem] 500px:h-[25rem] 700px:h-[35rem] 1024px:h-[35rem] 1024px:w-[130%] 1024px:absolute right-0 top-[4em] grid grid-cols-2 grid-rows-5 gap-5">
            <div className="w-[100%] row-span-3">
              <ImageCurve image="https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/home%2FCEPYME%20INICIO.%2001.jpg?alt=media&token=e062aee0-916e-47cd-8882-2b4107439794" />
            </div>
            <div className="w-[100%] row-span-2">
              <ImageCurve image="https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/home%2FCEPYME%20INICIO.%2002.jpg?alt=media&token=0a1a93e8-5d6e-4c21-b8ba-b054ac914ecc" />
            </div>
            <div className="w-[100%] row-span-3">
              <ImageCurve image="https://firebasestorage.googleapis.com/v0/b/cepyme-5facf.appspot.com/o/home%2FCEPYME%20INICIO.%2003.jpg?alt=media&token=60c4a8fa-d9e0-4a3d-9d8e-34dbc025a50e" />
            </div>
            <div className="ml-auto w-[27vw] h-[27vw] 1024px:w-[7em] 1024px:h-[7em] row-span-2 bg-primary rounded-tl-image-curve rounded-br-image-curve 1024px:rounded-tl-image-curve-desktop 1024px:rounded-br-image-curve-top"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
