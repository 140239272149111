import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { placeholderInput, textInput } from "../../../utils/Texts";
import { requestContact } from "../../../service/sectionsActions";
import GeneralContext from "../../general/generalContext/GeneralContext";
import firebase from "firebase";

interface ContactFormProps {
  className?: string; // La prop className es opcional
}

const ContactForm = ({ className }: ContactFormProps): JSX.Element => {
  const { runLoading, stopLoading }: any = useContext(GeneralContext);
  const [, setShowPopUp] = useState<boolean>(false);

  const [form, setForm] = useState<any>({
    name: "",
    mail: "",
    commentary: "",
    option1: false,
    option2: false,
    option3: false
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    runLoading();
    const response = await requestContact(form as JSON);

    if (response === "OK") {
      setShowPopUp(true);
      firebase.analytics().logEvent("Página", { name: "Contactada" });
      setForm({
        name: "",
        mail: "",
        commentary: "",
        option1: false,
        option2: false,
        option3: false
      });
    }

    stopLoading();
  };
  return (
    <form onSubmit={handleSubmit} className={className}>
      <div className="w-full max-w-[550px] flex flex-col justify-center items-center pb-6 px-6 ">
        <h3 className=" self-stretch text-neutral-900 text-2xl font-normal  leading-9 mt-10">
          Formulario de contacto
        </h3>
        <div className=" w-full border border-stone-300 mb-2"></div>

        <div className="w-full   ">
          <span className="block mb-3 mx-3">{textInput.name.es}</span>
          <input
            type="text"
            name="name"
            value={form.name}
            onChange={(e) =>
              setForm({
                ...form,
                name: e.target.value
              })
            }
            placeholder={placeholderInput.name.es}
            className="w-full rounded-lg px-5 py-3 mb-8 800px:rounded-xl 800px:mb-8 border border-black outline-none"
            required
          />
        </div>
        <div className="w-full ">
          <span className="block mb-3 mx-3">{textInput.email.es}</span>
          <input
            type="text"
            name="mail"
            value={form.mail}
            onChange={(e) =>
              setForm({
                ...form,
                mail: e.target.value
              })
            }
            placeholder={placeholderInput.email.es}
            className="w-full rounded-lg px-5 py-3 mb-8  800px:rounded-xl 800px:mb-8 border border-black outline-none"
            required
          />
        </div>
        <div className="w-full ">
          <span className="block mb-3 mx-3">{textInput.commentary.es}</span>
          <textarea
            name="commentary"
            value={form.commentary}
            onChange={(e) =>
              setForm({
                ...form,
                commentary: e.target.value
              })
            }
            className=" w-full resize-none rounded-lg px-5 py-3 h-40  800px:rounded-xl border border-black outline-none"
            required
          />
        </div>
        <div className="w-full flex flex-col items-center ">
          <label className="flex pt-5">
            <input
              type="checkbox"
              className="mt-1"
              checked={form.option1}
              onChange={() => setForm({ ...form, option1: !form.option1 })}
              required
            />
            <span className="pl-2 text-color-light opacity-60">
              He leído la &nbsp;
              <Link to="/politica-datos" target="_blank" className="underline">
                <b>
                  POLÍTICA DE CEPYME S.A.S. PARA LA PROTECCIÓN Y TRATAMIENTO DE
                  DATOS PERSONALES
                </b>
              </Link>
              , la cual se encuentra publicada en &nbsp;
              <Link to="/politica-datos" target="_blank" className="underline">
                <b>https://cepyme.com.co/politica-datos</b>
              </Link>
            </span>
          </label>

          <label className="flex pt-5 ">
            <input
              type="checkbox"
              className="mt-1"
              checked={form.option2}
              onChange={() => setForm({ ...form, option2: !form.option2 })}
              required
            />
            <span className="pl-2 text-color-light opacity-60">
              Autorizo el Tratamiento de mis Datos Personales de conformidad con
              la &nbsp;
              <Link to="/politica-datos" target="_blank" className="underline">
                <b>
                  POLÍTICA DE CEPYME S.A.S. PARA LA PROTECCIÓN Y TRATAMIENTO DE
                  DATOS PERSONALES
                </b>
              </Link>
              , la cual se encuentra publicada en &nbsp;
              <Link to="/politica-datos" target="_blank" className="underline">
                <b>https://cepyme.com.co/politica-datos</b>
              </Link>
            </span>
          </label>
          <label className="flex pt-5">
            <input
              type="checkbox"
              className="mt-1"
              checked={form.option3}
              onChange={() => setForm({ ...form, option3: !form.option3 })}
              required
            />
            <span className="pl-2 text-color-light opacity-60">
              Declaro que la información entregada es veraz, completa y que está
              actualizada, y me comprometo a informarla en caso de que cambie
            </span>
          </label>
        </div>
        <button
          className="w-full  bg-primary  text-white py-2 mt-5 rounded-md hover:bg-buttons"
          type="submit"
        >
          Enviar
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
