import React, { useEffect, useState } from "react";
import { getAllCategories } from "../../../service/action/getAirTable";
import { formatDataFromBackend } from "./Tags";

interface Tag {
  id: string;
  name: string;
}

interface CategoryTagProps {
  category: string;
}

const TagCategory = ({ category }: CategoryTagProps): JSX.Element | null => {
  const [tags, setTags] = useState<Tag[]>([{ id: "1", name: "Todos" }]);

  const getCategory = async (): Promise<void> => {
    const categories = await getAllCategories();
    if (!categories.data) return;
    const tagsFormated = formatDataFromBackend(categories.data.records);
    if (!tagsFormated) return;
    setTags((tags) => [...tags, ...tagsFormated]);
  };

  useEffect(() => {
    getCategory();
  }, []);
  const getTag = (activeTag: string): string => {
    const tag = tags.find((tag) => tag.id == activeTag);
    if (!tag) return "";
    return tag.name;
  };

  const tagToShow = getTag(category);

  if (tagToShow) {
    return (
      <div className="w-auto min-w-[60px] h-[25px] px-2 py-1  bg-white bg-opacity-20 rounded-[20px]  flex justify-center items-center">
        <div className="text-white tex-center font-normal">{tagToShow}</div>
      </div>
    );
  }

  return null;
};

export default TagCategory;
