interface Params {
  image: string;
}

const ImageCurve = ({ image }: Params): any => {
  return (
    <img
      src={image}
      className="w-full h-full relative object-cover  rounded-tl-image-curve rounded-br-image-curve 1024:rounded-tl-image-curve-desktop 1024:rounded-br-image-curve-top"
      alt=""
    />
  );
};

export default ImageCurve;
