import React from "react";
import { TeamMemberModel } from "../../general/models/TeamMemberModel";
import pattern from "../../../assets/pattern-nosotros.svg";
import PrincipalButton from "../../general/buttons/PrincipalButton";

interface Params {
  member: TeamMemberModel;
  button: string;
  handleClick: () => void;
  positionImage?: number;
}

const TeamMemberComponent = ({
  member,
  button,
  handleClick,
  positionImage
}: Params): any => {
  
  return (
    <div className="relative 600px:px-10 800px:px-0 mx-4 800px:mx-0">
      <div
        className={`absolute bottom-0 h-14 mt-3 w-full flex lg:h-20 ${
          positionImage === 2
            ? "justify-center mb-14% 600px:mb-11% 600px:ml-10.5% 800px:mb-8 800px:ml-1 1024px:mb-6 1024px:ml-0"
            : positionImage === 3
            ? "justify-center 800px:justify-end mb-14% 600px:mb-11% 600px:ml-17% 800px:mb-8 800px:-ml-5 900px:-ml-9 1024px:mb-6 1024px:-ml-0 1280px:-ml-6 1500px:-ml-20"
            : "justify-center 800px:justify-start mb-14% 600px:mb-11% 600px:ml-8% 800px:mb-8 800px:ml-5 900px:ml-9 1024px:mb-6 1024px:ml-0 1280px:ml-6 1500px:ml-20"
        }`}
      >
        <img src={pattern} alt="" />
      </div>
      <div className="bg-white mx-auto 800px:mx-11 1024px:mx-5 1280px:mx-16 my-16 rounded-xl w-14rem h-13rem shadow-lg flex flex-col items-center relative 800px:w-44 1024px:w-14rem">
        <img
          src={member.image}
          alt=""
          className="rounded-full w-5.5rem h-5.5rem absolute -top-14 object-cover object-top 800px:w-20 800px:h-20"
        />
        <p className="text-black font-semibold text-lg pt-12 800px:text-base">
          {member.name}
        </p>
        <p className="p-3 text-color-light text-sm text-center font-normal leading-5">
          {member.text}
        </p>
        <PrincipalButton
          className="mt-auto mb-6"
          text={button}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

export default TeamMemberComponent;
