import { useState } from "react";
import { FrequentQuestionsModel } from "./FrequentQuestionsModel";
import moreYellow from "../../../assets/more-yellow.svg";

const FrequentQuestionsComponent = (props: FrequentQuestionsModel): any => {
  const [changeMore, setChangeMore] = useState(false);

  return (
    <div className="border-b-2 border-divider w-85vw 800px:w-76vw max-w-[1532px] mx-auto">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setChangeMore(!changeMore)}
      >
        {changeMore ? (
          <div className="bg-primary w-5 h-1 ml-3 mr-4"></div>
        ) : (
          <img
            src={moreYellow}
            alt=""
            className="w-4 ml-3 mr-5 transform rotate-45"
            style={{ transform: `rotate(${changeMore ? 180 : 0})deg` }}
          />
        )}
        <p className="text-dark-blue py-2 font-semibold text-base 800px:py-4 1280px:text-xl text-start">
          {props.question}
        </p>
      </div>
      <div style={{ height: changeMore ? "auto" : 0 }}>
        <p
          className={
            changeMore
              ? "text-sm transition-all text-color-light text-start mx-5 pb-5 1280px:text-lg font-normal leading-5 pt-0 800px:pt-3"
              : "hidden"
          }
        >
          {props.answer}
        </p>
      </div>
    </div>
  );
};

export default FrequentQuestionsComponent;
