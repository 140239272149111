import React from "react";
import FrequenlyQuestionsComponent from "./FrequentQuestionsComponent";
import { questions, titleSection } from "../../../utils/Texts";
import TitleSection from "../../general/texts/TitleSection";

const FrequentQuestions = (): any => {
  return (
    <div className="bg-page py-8 max-w-[1532px] mx-auto">
      <div className="px-11">
        <TitleSection
          left={true}
          text={titleSection.frequentQuestions.es}
          lineTop={false}
        />
      </div>
      <div className="flex justify-center 800px:justify-start items-center mt-5 ml-10">
        <div className="bg-primary w-40 h-1"></div>
      </div>
      <div className="flex justify-center items-center mt-3 1024px:mt-16">
        <div className="flex flex-col my-5">
          {questions.map((question, i) => {
            return (
              <div key={`questions-${i}`}>
                <FrequenlyQuestionsComponent
                  question={question.question.es}
                  answer={question.answer.es}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FrequentQuestions;
